import { API_BASE_URL } from "./config";
import CryptoJS from "crypto-js";
import PhoneNumber from "awesome-phonenumber";
import moment from "moment";
import momentWithTimeZome from "moment-timezone";
import jsPDF from "jspdf";

export default {
  data() {
    return {
      subDomain: "",
      debounceTimer: null,
      entityTypeSelectItems: [
        { id: 0, value: "private", text: "" },
        { id: 1, value: "company", text: "" },
      ],
      statusFilters: {
        estimation: ["open", "waiting", "closed"],
        purchase_order: ["open", "waiting", "closed", "canceled"],
        bill: ["waiting", "partial_payed", "payed", "canceled"],
        credit_note: ["waiting", "partial_payed", "payed", "canceled"],
      },
      listingStatusFilters: {
        estimation: ["open", "waiting", "closed"],
        purchase_order: ["open", "waiting", "closed", "canceled"],
        bill: ["open", "waiting", "partial_payed", "payed", "canceled"],
        credit_note: ["open", "waiting", "partial_payed", "payed", "canceled"],
      },
      cancelledStatusFilters: ["canceled", "closed"],
      creditNoteMultiplicationDefaultValue: {
        value: 1,
      },
      maxWidthForComp: 200,
      maxWidthForCompWithAverageWidth: 140,
      maxWidthForCompWithSmallWidth: 100,
      maxWidthForCompWithVerySmallWidth: 60,
      availableLocales: [],
      allowedPaymentMethodsForAllowedUsers: [
        "cash",
        "visa",
        "master_card",
        "amex",
        "debit_card",
        "compensation",
        "credit_note",
      ],
      currentDate: null,
      currentDateTime: null,
      allIntervals: [],
      monthsArray: [
        { id: 1, key: "january" },
        { id: 2, key: "february" },
        { id: 3, key: "march" },
        { id: 4, key: "april" },
        { id: 5, key: "may" },
        { id: 6, key: "june" },
        { id: 7, key: "july" },
        { id: 8, key: "august" },
        { id: 9, key: "september" },
        { id: 10, key: "october" },
        { id: 11, key: "november" },
        { id: 12, key: "december" },
      ],
    };
  },
  computed: {
    paymentMethods() {
      return this.$store.state.classCodes["payment_types"];
    },
    availableResources() {
      let data = JSON.parse(localStorage.getItem("data"));
      if (data) {
        let applicationResources = [];
        data.applicationResources.forEach((resource) => {
          if (resource.application_resource.key) {
            resource.application_resource.text = this.$t(
              "attachments_module.application_resources." +
                resource.application_resource.key
            );
            applicationResources.push(resource.application_resource);
          }
        });
        return applicationResources;
      }
      return [];
    },
    attachmentResources() {
      if (this.availableResources && this.availableResources.length > 0) {
        let attachmentResources = ["billing", "vehicles", "client"];
        return this.availableResources.filter((element) => {
          if (attachmentResources.includes(element.key)) {
            return element;
          }
          return false;
        });
      }
      return [];
    },
    userRole() {
      return JSON.parse(localStorage.getItem("data")).userDetails.role;
    },
    currentTimeZone() {
      return momentWithTimeZome.tz.guess() ?? "Europe/Paris";
    },
    daysOfWeek() {
      return [
        this.$t("days.sunday"),
        this.$t("days.monday"),
        this.$t("days.tuesday"),
        this.$t("days.wednesday"),
        this.$t("days.thursday"),
        this.$t("days.friday"),
        this.$t("days.saturday"),
      ];
    },
    getDueDate() {
      let defaultDueDays = this.getCompanyPropertyFromLocalStorage(
        "DEFAULT_DOCUMENT_DUE_DAYS"
      )
        ? this.getCompanyPropertyFromLocalStorage("DEFAULT_DOCUMENT_DUE_DAYS")
            .value
        : 30;
      //
      let newDueDate = new Date(
        new Date().getTime() + defaultDueDays * 24 * 60 * 60 * 1000
      );

      return newDueDate.toISOString().substr(0, 10);
    },
  },
  beforeMount() {
    this.getLocales();
  },
  beforeDestroy() {
    this.clearAllIntervals();
  },
  mounted() {
    this.subDomain = this.getCurrentSubdomain();
    this.entityTypeSelectItems = this.translateKeys(
      this.entityTypeSelectItems,
      "value",
      "entity_type"
    );
    this.updateDateTime(); // Initialize the values
    // const updatingDateTimeInterval = setInterval(this.updateDateTime, 1000); // Update every second
    // this.storeInterval(updatingDateTimeInterval);
  },
  methods: {
    getMonthByMonthNumber(monthNumber, translate = true, returnObject = false) {
        let monthName = "";
        this.monthsArray.forEach((element) => {
            if(element.id == monthNumber){
                element.text = translate ? this.$t('months.' + element.key) : element.key;
                monthName = element;
            }
        })
        return returnObject ? monthName : monthName.text;;
    },
    getTaxationReasonBackgroundColor(taxation_reason_id) {
      if (taxation_reason_id && typeof taxation_reason_id == "object") {
        if (taxation_reason_id.key == "intracommunity") {
          return "redColorBackground";
        }
        // if(taxation_reason_id.key == 'no_reason'){
        //     return 'green'
        // }
      }
      if (taxation_reason_id && typeof taxation_reason_id == "number") {
        if (taxation_reason_id == 10771001) {
          return "redColorBackground";
        }
      }
    },
    getDeviceInfo(returnString = false) {
      const userAgent = navigator.userAgent;
      const platform = navigator.platform;
      const vendor = navigator.vendor || "Unknown Vendor";
      const language = navigator.language || "Unknown Language";

      // Extract Browser Name and Version
      let browserName = "Unknown Browser";
      let browserVersion = "Unknown Version";

      if (/firefox/i.test(userAgent)) {
        browserName = "Firefox";
        browserVersion =
          userAgent.match(/firefox\/(\d+\.\d+)/i)?.[1] || "Unknown";
      } else if (/chrome|chromium|crios/i.test(userAgent)) {
        browserName = "Chrome";
        browserVersion =
          userAgent.match(/chrome\/(\d+\.\d+)/i)?.[1] || "Unknown";
      } else if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
        browserName = "Safari";
        browserVersion =
          userAgent.match(/version\/(\d+\.\d+)/i)?.[1] || "Unknown";
      } else if (/edg/i.test(userAgent)) {
        browserName = "Edge";
        browserVersion = userAgent.match(/edg\/(\d+\.\d+)/i)?.[1] || "Unknown";
      } else if (/opera|opr/i.test(userAgent)) {
        browserName = "Opera";
        browserVersion =
          userAgent.match(/(opera|opr)\/(\d+\.\d+)/i)?.[2] || "Unknown";
      } else if (/msie|trident/i.test(userAgent)) {
        browserName = "Internet Explorer";
        browserVersion =
          userAgent.match(/(msie |rv:)(\d+\.\d+)/i)?.[2] || "Unknown";
      }

      // Extract OS
      let os = "Unknown OS";
      if (/win/i.test(platform)) {
        os = "Windows";
      } else if (/mac/i.test(platform)) {
        os = "MacOS";
      } else if (/linux/i.test(platform)) {
        os = "Linux";
      } else if (/android/i.test(userAgent)) {
        os = "Android";
      } else if (/iphone|ipad|ipod/i.test(userAgent)) {
        os = "iOS";
      }

      // Generate a unique device fingerprint
      const fingerprint = btoa(
        `${browserName}${browserVersion}${os}${platform}${vendor}${language}`
      );

      if (returnString) {
        return `browser=${browserName} version=${browserVersion} os=${os} platform=${platform} vendor=${vendor} language=${language}`;
      }
      return {
        browser: browserName,
        version: browserVersion,
        os: os,
        platform: platform,
        vendor: vendor,
        language: language,
        fingerprint: fingerprint, // Encoded unique identifier
      };
    },
    maskEmail(email) {
      const [localPart, domain] = email.split("@");

      if (!localPart || !domain) {
        return email; // Return as is if invalid
      }

      // Keep the first and last character, mask the rest with 'xx'
      const maskedLocal =
        localPart.length > 2
          ? localPart[0] + "xxxxxx" + localPart.slice(-1)
          : localPart[0] + "xxxxxx";

      return `${maskedLocal}@${domain}`;
    },
    getCurrentSubdomain() {
      const hostname = window.location.hostname; // Get current domain
      const parts = hostname.split(".");

      if (parts.length > 2) {
        return parts.slice(0, -2).join("."); // Extracts subdomain
      }

      return "CXP"; // No subdomain found
    },
    handleVisibilityChange() {
      this.$store.commit("setIsTabActive", !document.hidden);
      if (this.$store.state.isTabActive) {
        //   console.log("User is back on the page!", this.$store.state.isTabActive);
        this.$store.commit("setNewChangesCount", 0); // Reset count when user returns
      } else {
        // console.log("User moved out of the page!", this.$store.state.isTabActive);
      }
    },
    checkAndTriggerNotificationInTitle(oldData, newData) {
      console.log("notification check");
      if (oldData && newData && newData.length > 0) {
        let allIds =
          "-" +
          oldData
            .map((element) => {
              return element.id;
            })
            .join("-") +
          "-";
        let countOfNewIds = 0;
        let idsNew = [];
        newData.forEach((newElement) => {
          if (newElement.id) {
            let searchString = "-" + newElement.id + "-";
            if (!allIds.includes(searchString)) {
              countOfNewIds++;
              idsNew.push(newElement.id);
            }
          }
        });
        console.log(
          "notification new ids",
          this.newChangesCount,
          countOfNewIds,
          this.$store.state.isTabActive
        );
        if (countOfNewIds > 0 && !this.$store.state.isTabActive) {
          this.$store.commit(
            "setNewChangesCount",
            this.$store.state.newChangesCount + countOfNewIds
          );
          document.title =
            "(" +
            this.$store.state.newChangesCount +
            ") " +
            document.title.substring(document.title.indexOf(")") + 1);
          this.playNotificationSound();
        } else if (this.$store.state.isTabActive) {
          document.title = document.title.substring(
            document.title.indexOf(")") + 1
          );
        }
      }
    },
    playNotificationSound() {
      let data = { soundurl: "/phone-notification-311064.mp3" };

      let audio = new Audio(data.soundurl);
      audio.volume = 0; // Mute initially
      audio.play().catch(() => {
        console.log("Autoplay blocked, will play on interaction.");
      });

      if (audio) {
        audio.volume = 1; // Restore volume
        audio.currentTime = 0;
        audio.play().catch(() => {
          console.log("Autoplay blocked, while playing on volume 1");
        });
      }
    },
    firstIconIsVisible(appointment) {
      return appointment.data.original_start_time < appointment.data.start_time;
    },
    secondIconIsVisible(appointment) {
      return appointment.data.original_start_time > appointment.data.start_time;
    },
    thirdIconIsVisible(appointment) {
      return (
        appointment &&
        appointment.data &&
        appointment.data.appointment_status &&
        appointment.data.appointment_status.key == "appointment_canceled"
      );
    },
    fourthIconIsVisible(appointment) {
      // return (
      //     appointment &&
      //     appointment.data &&
      //     appointment.data.external_order_status &&
      //     appointment.data.external_order_status !== null
      // );
      return appointment && appointment.data && appointment.data.order_id;
    },

    fifthIconIsVisible(appointment) {
      return (
        appointment && appointment.data && appointment.data.depot_out_status_key
      );
    },

    sixthIconIsVisible(appointment) {
      return (
        appointment &&
        appointment.data &&
        appointment.data.is_end_of_client_tyre_hotel_article
      );
    },

    seventhIconIsVisible(appointment) {
      return (
        appointment &&
        appointment.data &&
        appointment.data.order_status_id == 10251003 // Payed
      );
    },

    calculateIconRightMargin(position, appointment) {
      let increment = 25;
      let initial = 2;
      if (position == "second") {
        return this.firstIconIsVisible(appointment) ? "22px" : initial + "px";
      }

      if (position == "third") {
        let rightMargin = initial;
        if (this.firstIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.secondIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        return rightMargin + "px";
      }

      if (position == "fourth") {
        let rightMargin = 2;
        if (this.firstIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.secondIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.thirdIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        return rightMargin + "px";
      }

      if (position === "fifth") {
        let rightMargin = 2;
        if (this.firstIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.secondIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.thirdIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.fourthIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        return rightMargin + "px";
      }

      // sixth position
      if (position === "sixth") {
        let rightMargin = 2;
        if (this.firstIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.secondIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.thirdIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.fourthIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.fifthIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        return rightMargin + "px";
      }

      // seventh position
      if (position === "seventh") {
        let rightMargin = 2;
        if (this.firstIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.secondIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.thirdIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.fourthIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.fifthIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.sixthIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        return rightMargin + "px";
      }

      // eighth position
      if (position === "eighth") {
        let rightMargin = 2;
        if (this.firstIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.secondIconIsVisible(appointment)) {
          rightMargin += increment;
        }
        if (this.thirdIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.fourthIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.fifthIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.sixthIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        if (this.seventhIconIsVisible(appointment)) {
          rightMargin += increment;
        }

        return rightMargin + "px";
      }

      return initial + "px";
    },
    computeExternalOrderStatus(status) {
      if (status == "success") {
        return {
          icon: "mdi-check-bold",
          color: "green",
        };
      } else if (status == "in_progress") {
        return {
          icon: "mdi-timer-sand",
          color: "orange",
        };
      } else if (status == "error") {
        return {
          icon: "mdi-information",
          color: "red",
        };
      } else if (status == "error_delivery_time") {
        return {
          icon: "mdi-information",
          color: "red",
        };
      } else {
        return {
          icon: "mdi-timer-sand",
          color: "red",
        };
      }
    },
    generatePaymentText(payments) {
      let textString = " ";
      if (payments && payments.length > 0) {
        payments.forEach((element) => {
          textString +=
            this.getPaymentMethodById(element.payed_by_id) +
            "(" +
            element.amount +
            "), ";
        });
      }
      if (textString.length > 2) {
        textString = textString.substring(0, textString.length - 2);
      }
      return textString;
    },
    getOrderTypeById(id) {
      let foundBillType = this.billTypes.find((element) => {
        if (element.id == id) {
          return element;
        }
      });
      return foundBillType;
    },
    clearAllIntervals() {
      const storedIntervals =
        JSON.parse(localStorage.getItem("all_intervals")) || [];

      // Step 2: Iterate through all intervals and set them
      storedIntervals.forEach((interval) => {
        clearInterval(interval);
      });
    },
    storeInterval(interval) {
      if (!this.allIntervals) {
        this.allIntervals =
          JSON.parse(localStorage.getItem("all_intervals")) || [];
      }

      this.allIntervals.push(interval);
      localStorage.setItem("all_intervals", JSON.stringify(this.allIntervals));
    },
    updateDateTime() {
      let newDate = new Date();

      this.currentDate = newDate.toISOString().substr(0, 10);

      let year = newDate.getFullYear();
      let month = String(newDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      let day = String(newDate.getDate()).padStart(2, "0");
      let hours = String(newDate.getHours()).padStart(2, "0");
      let minutes = String(newDate.getMinutes()).padStart(2, "0");

      this.currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
      return this.currentDateTime;
    },
    checkIfPaymentRefunded(payments) {
      if (payments && payments.length > 0) {
        let isRefunded = false;
        payments.forEach((payment) => {
          if (
            payment.transaction_id &&
            payment.transaction &&
            payment.transaction.status_id == 10850003
          ) {
            isRefunded = true;
          }
        });
        return isRefunded;
      }
      return false;
    },
    checkIfPaymentTransactionExist(payments) {
      if (payments && payments.length > 0) {
        let isExists = false;
        payments.forEach((payment) => {
          if (payment.transaction_id) {
            isExists = true;
          }
        });
        return isExists;
      }
      return false;
    },
    getSupplierOrderTextColor(item) {
      if (
        item &&
        item.supplier &&
        item.supplier.length > 0 &&
        item.saved_supplier_order_detail &&
        [10091000, 10091001, 10091004].includes(
          item.saved_supplier_order_detail.supplier_order.status_id
        ) &&
        item.saved_supplier_order_detail.delivery_date &&
        new Date(item.saved_supplier_order_detail.delivery_date) >= new Date()
      ) {
        return "orange";
      } else if (
        item &&
        item.supplier &&
        item.supplier.length > 0 &&
        item.saved_supplier_order_detail &&
        [10091000, 10091001, 10091002, 10091003, 10091004].includes(
          item.saved_supplier_order_detail.supplier_order.status_id
        ) &&
        item.saved_supplier_order_detail.delivery_date &&
        new Date(item.saved_supplier_order_detail.delivery_date) < new Date()
      ) {
        return "red";
      } else if (
        item &&
        item.supplier &&
        item.supplier.length > 0 &&
        item.saved_supplier_order_detail &&
        [10091005].includes(
          item.saved_supplier_order_detail.supplier_order.status_id
        )
      ) {
        return "green";
      }
      return "black";
    },
    calculateWithoutTva(totalValue) {
      let tvaDetails = JSON.parse(localStorage.getItem("data"))?.tvaDetails;
      if (totalValue && tvaDetails) {
        return totalValue / (1 + tvaDetails.tax / 100);
      }
      return totalValue;
    },
    getPaymentMethodById(id) {
      let paymentMethod = "";
      if (this.paymentMethods && this.paymentMethods.length > 0) {
        this.paymentMethods.forEach((element) => {
          if (element.id == id) {
            paymentMethod = element.text ?? element.key;
          }
        });
      }
      return paymentMethod;
    },
    filterPaymentMethods(paymentMethods) {
      let paymentMethodBasedOnActiveResource = [];
      // Only hide from the users which are not allowed to see payment methods like bank transfer
      paymentMethods.forEach(() => {
        paymentMethods.forEach((element) => {
          // If `administrative_payment_methods` tag is not required, show all payment methods
          if (!this.checkTag("administrative_payment_methods")) {
            paymentMethodBasedOnActiveResource.push(element);
            return; // Skip further checks
          }

          // If the user is allowed to see all administrative payment methods
          if (this.checkUserTag("administrative_payment_methods")) {
            paymentMethodBasedOnActiveResource.push(element);
          }
          // Otherwise, filter based on allowed payment methods
          else if (
            this.allowedPaymentMethodsForAllowedUsers.includes(element.key)
          ) {
            paymentMethodBasedOnActiveResource.push(element);
          }
        });
      });
      return paymentMethodBasedOnActiveResource;
    },
    getUserRoleResourceForResource(resourceToBeChecked) {
      let data = JSON.parse(localStorage.getItem("data"));
      let applicationResourceId = null;
      let defaultData = {
        can_view: 0,
        can_add: 0,
        can_edit: 0,
        can_delete: 0,
      };
      data.applicationResources.forEach((resource) => {
        if (resource.application_resource.key == resourceToBeChecked) {
          applicationResourceId = resource.application_resource.id;
        }
      });
      if (applicationResourceId) {
        let foundUserRoleResource = null;
        data.userDetails.role?.resources?.forEach((resource) => {
          if (resource.application_resource_id == applicationResourceId) {
            foundUserRoleResource = resource;
          }
        });
        if (foundUserRoleResource) {
          return foundUserRoleResource;
        }
      }
      return defaultData;
    },
    checkIfReclamationNeedToShowForMainClient(client) {
      if (client && typeof client == "object" && client.is_leaser == 0) {
        // Do not show reclamation for leaser like "ALD AUTOMOTIVE"
        return true;
      }
      return false;
    },
    checkIfReclamationNeedToShow(client) {
      if (client && typeof client == "object") {
        // Show only if it is an object
        return true;
      }
      return false;
    },
    disableOrderOrExpenseStatus(document) {
      if (
        document &&
        document.status_id &&
        document.status_id == 10251003 &&
        !this.isAdmin
      ) {
        return true;
      }
      return false;
    },
    makeSingleLineText(text) {
      let regex = /(<([^>]+)>)/gi;
      return (text ?? "").replace(regex, "");
    },
    downloadCSVFromJson(filename, csvHeaders, arrayOfJson) {
      // convert JSON to CSV
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = csvHeaders;
      let csv = arrayOfJson.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(";")
      );
      csv.unshift(header.join(";"));
      csv = csv.join("\r\n");

      // Create link and download
      let link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
      );
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    createPDFfromJson(filename, csvHeaders, arrayOfJson) {
      const doc = new jsPDF();
      const margin = 10;
      const startX = margin;
      let startY = margin + 10;
      const lineHeight = 10;
      const pageWidth = doc.internal.pageSize.width - 2 * margin;
      const pageHeight = doc.internal.pageSize.height;

      // Calculate maximum text width for each column
      const columnWidths = csvHeaders.map((header) => {
        let maxWidth = doc.getTextWidth(header);
        arrayOfJson.forEach((row) => {
          const text = row[header] ? row[header].toString() : "";
          const textWidth = doc.getTextWidth(text);
          if (textWidth > maxWidth) maxWidth = textWidth;
        });
        return maxWidth + 15; // Add some padding
      });

      // Scale column widths proportionally to fit the page width
      const totalWidth = columnWidths.reduce((a, b) => a + b, 0);
      if (totalWidth > pageWidth) {
        const scaleFactor = pageWidth / totalWidth;
        for (let i = 0; i < columnWidths.length; i++) {
          columnWidths[i] *= scaleFactor;
        }
      }

      // Function to wrap text within a specific column width
      const wrapText = (text, columnWidth) => {
        const words = text.split(" ");
        let lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          const width = doc.getTextWidth(currentLine + " " + word);
          if (width < columnWidth) {
            currentLine += " " + word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
        lines.push(currentLine);
        return lines;
      };

      // Print the header row
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      let currentX = startX;
      csvHeaders.forEach((header, index) => {
        doc.text(header, currentX, startY);
        currentX += columnWidths[index];
      });

      // Print the data rows
      doc.setFont("helvetica", "normal");
      startY += lineHeight;

      arrayOfJson.forEach((row) => {
        let maxLinesInRow = 1;
        currentX = startX;

        csvHeaders.forEach((fieldName, index) => {
          let text = row[fieldName] ? row[fieldName].toString() : "";
          const wrappedText = wrapText(text, columnWidths[index] - 2); // Adjust for padding

          // Print each line of wrapped text
          wrappedText.forEach((line, lineIndex) => {
            const yPos = startY + lineIndex * lineHeight;
            doc.text(line, currentX, yPos);
          });

          maxLinesInRow = Math.max(maxLinesInRow, wrappedText.length);
          currentX += columnWidths[index];
        });

        // Move to the next row
        startY += maxLinesInRow * lineHeight;

        // Check for page overflow and add a new page if needed
        if (startY + lineHeight > pageHeight) {
          doc.addPage();
          startY = margin + 10;
        }
      });

      // Save the generated PDF
      doc.save(`${filename}.pdf`);
    },
    convertNumberToInternationalFormat(numberValue) {
      if (!numberValue) {
        numberValue = 0;
      }
      let localeSetting = this.getCompanyPropertyFromLocalStorage(
        "LOCALE_SETTING"
      )
        ? this.getCompanyPropertyFromLocalStorage("LOCALE_SETTING").value
        : null;
      if (localeSetting) {
        return new Intl.NumberFormat(localeSetting, {
          useGrouping: false,
          minimumFractionDigits: this.getDecimalNumber(),
          maximumFractionDigits: this.getDecimalNumber(),
        }).format(numberValue);
      }
      return new Intl.NumberFormat("en-US", {
        useGrouping: false,
        minimumFractionDigits: this.getDecimalNumber(),
        maximumFractionDigits: this.getDecimalNumber(),
      }).format(numberValue);
    },
    showFormattedNumber(number) {
      number = parseFloat(number).toFixed(this.getDecimalNumber());
      let value = this.convertNumberToInternationalFormat(
        number == 0 ? 0 : number
      );
      return value;
      // if(number){
      //     number = typeof number == 'string' ? number : number.toFixed(this.getDecimalNumber());
      //     return this.convertNumberToInternationalFormat(number);
      // }
      // return this.convertNumberToInternationalFormat((0).toFixed(this.getDecimalNumber()));
    },
    customParseFloat(value) {
      if (typeof value == "string") {
        return parseFloat(value.replace(",", ""));
      } else {
        return parseFloat(value);
      }
    },
    resetPDFViewerProperties() {
      this.$store.commit("setPreviewUrl", null);
      this.$store.commit("setPreviewMimeType", null);
      this.$store.commit("setIsShowFullScreen", true);
      this.$store.commit("setOpenInNewWindow", false);
    },
    getModuleId(toSearchResource) {
      let module_id = null;
      let data = JSON.parse(localStorage.getItem("data"));
      let applicationResourceId = null;
      data.applicationResources.forEach((resource) => {
        if (resource.application_resource.key == toSearchResource) {
          applicationResourceId = resource.application_resource.id;
        }
      });
      data.attachmentModules.forEach((modules) => {
        if (modules.application_resource_id == applicationResourceId) {
          module_id = modules.id;
        }
      });
      return module_id;
    },
    calculateNoOfDaysLeftInDueDate(dueDate) {
      if (dueDate) {
        dueDate = moment(moment(dueDate).format("YYYY-MM-DD"));
        let todayDate = moment(moment().format("YYYY-MM-DD"));
        return dueDate.diff(todayDate, "days");
      }
      return 0;
    },
    checkIfItemIsBillOrCreditNote(key) {
      if (key == "bill" || key == "credit_note") {
        return true;
      }
      return false;
    },
    checkIfItemIsPurchaseOrderOrBillOrCreditNote(key) {
      if (key == "purchase_order" || key == "bill" || key == "credit_note") {
        return true;
      }
      return false;
    },
    checkIfItemIsClosedOrCancelled(key) {
      if (key == "canceled" || key == "closed") {
        return true;
      }
      return false;
    },
    getColorOnTheBasisOfPayedValue(total, totalPayed) {
      if (parseFloat(total) != 0) {
        if (parseFloat(totalPayed) == parseFloat(total)) {
          return "green";
        } else if (parseFloat(totalPayed) == 0 && parseFloat(total)) {
          return "red";
        } else if (
          parseFloat(totalPayed) != 0 &&
          parseFloat(totalPayed) < parseFloat(total)
        ) {
          return "orange";
        }
      }
      return "red";
    },
    getColorOnTheBasisOfStatus(item, total, totalPayed, itemStatus) {
      if (itemStatus == "payed") {
        return "#006d00";
      }
      if (itemStatus == "partial_payed") {
        return "green";
      }
      if (itemStatus == "waiting") {
        if (this.calculateNoOfDaysLeftInDueDate(item.due_date) < 0) {
          return "red";
        }
        return "orange";
      }
      if (itemStatus == "open") {
        return "blue";
      }
      if (itemStatus == "approved") {
        return "skyblue";
      }
      if (itemStatus == "to_be_billed") {
        return "black";
      }
      return "grey";
    },
    getHeaderStyle(header) {
      // Implement your logic to calculate the header style
      // based on the header object (e.g., width and alignment)
      // Return an object with CSS properties.
      let headerWidthCss = this.getHeaderWidth(header, header.width, true);
      return {
        width: headerWidthCss,
        textAlign: (header.align || "start") + "!important", // Default to 'start'
      };
    },
    getHeaderWidth(header, width, returnValue = false) {
      if (width) {
        width = width + "";
        if (width.includes("px") || width.includes("%")) {
          return returnValue
            ? width
            : "width: " + width + ";" + "max-width: " + width;
        } else {
          return returnValue ? width + "%" : "width: " + width + "%;";
        }
      }
      return returnValue ? "150px" : "width: 150px";
    },
    getPayedStatus(total, totalPayed, itemStatus) {
      return this.$t("billings.statuses." + itemStatus);
    },
    generateDueDate(currentDate, clientPaymentTermDays) {
      // PAYMENT_TERMS is same as DEFAULT_DOCUMENT_DUE_DAYS, if it is present then it should be calculated on that basis otherwise it will be calculated on the basis of DEFAULT_DOCUMENT_DUE_DAYS
      let defaultDueDays = clientPaymentTermDays
        ? clientPaymentTermDays
        : this.getCompanyPropertyFromLocalStorage("DEFAULT_DOCUMENT_DUE_DAYS")
        ? this.getCompanyPropertyFromLocalStorage("DEFAULT_DOCUMENT_DUE_DAYS")
            .value
        : 30;
      //
      let newDueDate = new Date(
        (currentDate ? new Date(currentDate) : new Date()).getTime() +
          defaultDueDays * 24 * 60 * 60 * 1000
      );

      return newDueDate.toISOString().substr(0, 10);
    },
    checkIfAddressEmpty(address) {
      if (!address) {
        return true;
      } else if (
        address &&
        typeof address == "object" &&
        address?.street == null &&
        address?.building_id == null &&
        address?.place_id == null &&
        address?.zip == null &&
        address?.country == null &&
        address?.country_code == null &&
        address?.country_id == null &&
        address?.county == null &&
        address?.state == null &&
        address?.region == null &&
        address?.city == null &&
        address?.lat == null &&
        address?.long == null &&
        address?.provider == null &&
        address?.formatted == null &&
        address?.name == null &&
        address?.location_name == null
      ) {
        return true;
      }
      return false;
    },
    addSpaceAfterThirdChar(str) {
      let result = "";
      for (let i = 0; i < str.length; i++) {
        result += str.charAt(i);
        if ((i + 1) % 3 === 0 && i !== str.length - 1) {
          result += " ";
        }
      }
      return result;
    },
    findCountry(iso = "") {
      return this.preferredCountry.find(
        (country) => country === iso.toUpperCase()
      );
    },
    formatToPhoneNumber(val) {
      let phonenumber = val ?? "";
      let code = PhoneNumber(phonenumber).getRegionCode();
      let countryDialCode = PhoneNumber(phonenumber).getCountryCode();
      if (code && countryDialCode) {
        return (
          "+" +
          countryDialCode +
          " " +
          this.addSpaceAfterThirdChar(
            val
              .replace("+", "")
              .replaceAll(" ", "")
              .replace(countryDialCode, "")
          )
        );
      }
      return val;
    },
    /** Swap Article array elements from one position to another */
    swap_article_array_elements(arr, old_index, new_index) {
      arr.forEach(() => {});
      // let tempElement = arr[old_index];
      // arr[old_index] = arr[new_index];
      // arr[new_index] = tempElement;
      let removedItem = arr.splice(old_index, 1)[0];
      arr.splice(new_index, 0, removedItem);
      let count = 0;
      arr.forEach((element) => {
        element.srno = count++;
        element.row = count;
      });
      return arr;
    },
    getLocales() {
      return new Promise((resolve) => {
        this.availableLocales = [];
        const locales = require.context(
          "./locales",
          true,
          /[A-Za-z0-9-_,\s]+\.json$/i
        );
        locales.keys().forEach((key) => {
          const locale = key
            .replaceAll("/", "")
            .replaceAll(".json", "")
            .replaceAll(".", "");
          this.availableLocales.push(locale);
        });
        resolve();
      });
    },
    parseSymbol(symbolCode) {
      const htmlDom = new DOMParser().parseFromString(symbolCode, "text/html");
      return htmlDom.documentElement.textContent;
    },
    toLocalISOString(date) {
      if (date) {
        const localDate = new Date(date - date.getTimezoneOffset() * 60000); //offset in milliseconds

        // Optionally remove second/millisecond if needed
        localDate.setSeconds(null);
        localDate.setMilliseconds(null);
        return localDate.toISOString().slice(0, -1);
      }
    },
    setTopCurrencyBasedOnDefaultBank(bank_id = null) {
      if (!bank_id && this.defaultBankAccount) {
        bank_id = this.defaultBankAccount.id;
      }
      this.bankAccounts.forEach((element) => {
        if (element.id == bank_id) {
          this.$store.commit("setBankAccount", element);
        }
      });
    },
    async findCompanyLocale(passedLocale) {
      await this.getLocales();
      let data = JSON.parse(localStorage.getItem("data"));
      let companyIdentifier = data.userDetails.company.identifier;
      let userLanguage = localStorage.getItem("languageId");
      let localLocale = passedLocale
        ? companyIdentifier + "" + passedLocale
        : companyIdentifier + "" + userLanguage;
      let localFound = this.availableLocales.find((element) => {
        return element == localLocale;
      });

      let passedLocaleFound = this.availableLocales.find((element) => {
        return element == passedLocale;
      });
      if (localFound) {
        return localLocale;
      } else if (passedLocaleFound) {
        return passedLocale;
      } else {
        return userLanguage;
      }
    },
    selectDefaultBillType(billTypesArray, customDefault = null) {
      if (customDefault) {
        let foundBillType = billTypesArray.find((element) => {
          if (element.id == customDefault || element.key == customDefault) {
            return element;
          }
        });
        return foundBillType;
      }
      let billTypeCode = this.getCompanyPropertyFromLocalStorage(
        "DEFAULT_BILLING_DOCUMENT"
      );
      if (billTypeCode && billTypeCode.value) {
        let foundBillType = billTypesArray.find((element) => {
          if (element.id == billTypeCode.value) {
            return element;
          }
        });
        if (foundBillType) {
          return foundBillType;
        }
        return billTypesArray[1];
      }
      return billTypesArray[1];
    },
    selectDefaultBillTypeFromNavigationData() {
      let selectedBillType = null;
      this.billTypes.forEach((element) => {
        if (
          this.navigationData &&
          this.navigationData.name &&
          this.navigationData.name.includes("." + element.key)
        ) {
          selectedBillType = element;
          this.billType = element;
        }
      });

      if (!selectedBillType) {
        selectedBillType = this.selectDefaultBillType(this.billTypes);
      }
      return selectedBillType;
    },
    selectDefaultexpensesType(expensesTypesArray) {
      let expensesTypeCode = this.getCompanyPropertyFromLocalStorage(
        "DEFAULT_EXPENSES_DOCUMENT"
      );
      if (expensesTypeCode && expensesTypeCode.value) {
        let foundexpensesType = expensesTypesArray.find((element) => {
          if (element.id == expensesTypeCode.value) {
            return element;
          }
        });
        if (foundexpensesType) {
          return foundexpensesType;
        }
        return expensesTypesArray[0];
      }
      return expensesTypesArray[0];
    },
    selectDefaultExpenseTypeFromNavigationData() {
      let selectedExpenseType = null;
      this.expensesTypes.forEach((element) => {
        if (
          this.navigationData &&
          this.navigationData.name &&
          this.navigationData.name.includes("." + element.key)
        ) {
          selectedExpenseType = element;
          this.expenseType = element;
        }
      });

      if (!selectedExpenseType) {
        selectedExpenseType = this.selectDefaultexpensesType(
          this.expensesTypes
        );
      }
      return selectedExpenseType;
    },
    copyTextFromId() {
      document.getElementById("textToCopy").select();
      document.execCommand("copy");
    },
    checkTag(tag) {
      return (
        (
          JSON.parse(localStorage.getItem("applicationResources")) ?? []
        ).includes(tag) ?? false
      );
    },
    checkUserTag(tag) {
      let userRole = JSON.parse(localStorage.getItem("data")).userDetails.role;

      if (userRole.name === "admin") {
        return true;
      }

      return (
        userRole.resources.find((r) => r.application_resource.key === tag) ??
        false
      );
    },
    getDayViewWidth() {
      if (document.getElementById("scrollableDayView")) {
        return (
          parseInt(document.getElementById("scrollableDayView").offsetWidth) -
          150
        );
      }
      return 250;
    },
    setTableThTdWidth() {
      let boxes = document.querySelectorAll(".tablethtd");
      boxes.forEach((box) => {
        box.style.minWidth = `180px`;
      });
    },
    getDay(date) {
      let i = new Date(date).getDay(date);
      return this.daysOfWeek[i];
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    getDateByXDays(n) {
      return new Date(new Date().getTime() + n * 24 * 60 * 60 * 1000);
    },

    formatTime(dateTimeSting) {
      let date = new Date(dateTimeSting);
      return [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
      ].join(":");
    },
    getMonthAndYearInText(date) {
      date = new Date(date);
      return (
        this.$t("months.long." + date.getMonth()) + " " + date.getFullYear()
      );
    },
    getDayAndMonthInText(date) {
      if (date) {
        date = new Date(date);
        return (
          date.getDate() + " " + this.$t("months.short." + date.getMonth())
        );
      }
      return "";
    },
    getWeekNumber(date) {
      date = new Date(date);
      let oneJan = new Date(date.getFullYear(), 0, 1);
      return Math.ceil(((date - oneJan) / 86400000 + oneJan.getDay() + 1) / 7);
    },
    /**
     * It handles the type of values that can be entered in the text field based upon its datatypes
     * This is also responsible for the behaviour like : when max length reached no more data can be entered.
     */
    handleInput: function (event, dataType, maxlength, decimalPlaces) {
      event = event ? event : window.event;
      let charCode = event.which ? event.which : event.keyCode;
      if (
        charCode == 9 ||
        charCode == 8 ||
        charCode == 46 ||
        charCode === 16 ||
        charCode === 13 || // Shift Mac
        charCode === 17 || // Control Mac
        charCode === 18 || // Option Mac
        charCode === 91 || // Command Mac
        charCode === 93 || // Command Mac
        (charCode >= 37 && charCode <= 40) ||
        (charCode >= 48 && charCode <= 58)
      ) {
        return true;
      }

      if (
        maxlength != null &&
        (event.target.value + "").replace(".", "").length >=
          Number(maxlength) + Number(decimalPlaces ?? 0)
      ) {
        if (event.target.selectionStart == event.target.selectionEnd) {
          event.preventDefault();
        } else {
          if (event.target.selectionEnd == maxlength) {
            // Allow the user to delete the selected text and press the key typed
            return true;
          } else {
            event.preventDefault();
          }
        }
      }

      if (dataType === "int") {
        // Do not allow space as well
        if (
          charCode === 8 ||
          charCode === 16 ||
          charCode === 13 ||
          charCode === 86 ||
          /^[0-9]$/i.test(event.key)
        ) {
          return true;
        } else {
          return event.preventDefault();
        }
      }

      if (dataType == "float") {
        let dotExists = false;
        if ((event.target.value + "").indexOf(".") != -1) {
          dotExists = true;
        }
        if (
          !event.shiftKey &&
          ((charCode == 190 && !dotExists) ||
            charCode == 8 ||
            charCode === 16 ||
            charCode === 13 ||
            /^[0-9.,]$/i.test(event.key))
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }

      if (dataType == "alpha") {
        if (
          charCode == 8 ||
          charCode == 32 ||
          charCode === 16 ||
          charCode === 13 ||
          /^[a-zA-Z]$/i.test(event.key)
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }

      if (dataType == "email") {
        if (
          charCode == 8 ||
          charCode == 32 ||
          charCode === 16 ||
          charCode === 13 ||
          /^[a-zA-Z0-9@+-/_]$/i.test(event.key)
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }

      if (dataType == "alphanumeric") {
        if (
          charCode == 8 ||
          charCode == 32 ||
          charCode === 16 ||
          charCode === 13 ||
          /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key)
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }

      if (dataType == "alphanumericwithenter") {
        if (
          charCode == 8 ||
          charCode == 32 ||
          charCode == 13 ||
          charCode === 16 ||
          charCode === 13 ||
          /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key)
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }
      if (dataType == "everything") {
        // if ( charCode == 8 || charCode == 32 || charCode == 13 || ( /^[0-9a-zA-Z.,+:~#/_/-]$/i.test(event.key) ) ) {
        // return true;
        // } else {
        // event.preventDefault();
        // }
      }

      if (dataType == "justalphanumeric") {
        if (
          charCode == 8 ||
          charCode == 32 ||
          charCode === 16 ||
          charCode === 13 ||
          /^[0-9a-zA-Z]$/i.test(event.key)
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }
      if (dataType == "justalphanumericwithoutspace") {
        if (
          charCode == 8 ||
          charCode === 16 ||
          charCode === 13 ||
          /^[0-9a-zA-Z]$/i.test(event.key)
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }

      if (dataType == "alphanumdash") {
        if (
          charCode == 8 ||
          charCode == 32 ||
          charCode === 16 ||
          charCode === 13 ||
          /^[0-9a-zA-Z/-]$/i.test(event.key)
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }
      if (dataType == "alphanumdashdot") {
        if (
          charCode == 8 ||
          charCode == 32 ||
          charCode === 16 ||
          charCode === 13 ||
          /^[0-9a-zA-Z.-]$/i.test(event.key)
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }
      if (dataType == "phonenumber") {
        if (
          charCode == 8 ||
          charCode == 32 ||
          charCode === 16 ||
          charCode === 13 ||
          /^[0-9+ /)/(/-]$/i.test(event.key)
        ) {
          return true;
        } else {
          event.preventDefault();
        }
      }

      return true;
    },
    checkBrowser: function () {
      if (
        navigator.userAgent.indexOf("Firefox") > -1 ||
        typeof InstallTrigger !== "undefined"
      ) {
        return "firefox";
      }
      if (
        navigator.userAgent.indexOf("Safari") > -1 ||
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && window["safari"].pushNotification)
        )
      ) {
        return "safari";
      }

      if (
        navigator.userAgent.indexOf("MSIE") > -1 ||
        navigator.userAgent.indexOf("rv:") > -1 ||
        /*@cc_on!@*/ false ||
        !!document.documentMode
      ) {
        return "IE";
      }

      if (
        !(/*@cc_on!@*/ (false || !!document.documentMode)) &&
        !!window.StyleMedia
      ) {
        return "edge";
      }

      if (
        navigator.userAgent.indexOf("Chrome") > -1 ||
        (!!window.chrome &&
          (!!window.chrome.webstore || !!window.chrome.runtime))
      ) {
        return "chrome";
      }
    },
    autocompleteValue: function () {
      let browser = this.checkBrowser();
      if (browser == "firefox") {
        return "off";
      }
      if (browser == "chrome") {
        return "offf";
      }
      return "offf";
    },

    encryptData(pvtData) {
      pvtData = pvtData + "";
      let secret = this.getCurrentDate();
      return CryptoJS.AES.encrypt(pvtData, secret).toString();
    },

    decryptData(secretData) {
      let secret = API_BASE_URL + this.getCurrentDate();
      return CryptoJS.AES.decrypt(secretData, secret).toString(
        CryptoJS.enc.Utf8
      );
    },
    getCurrentDate(format = "mm/dd/yyyy") {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      if (format == "YYYY-mm-dd") {
        return yyyy + "-" + mm + "-" + dd;
      }
      return mm + "/" + dd + "/" + yyyy;
    },
    getDateDifference(startDate, endDate) {
      // Parse the dates
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Calculate the difference in milliseconds
      const differenceInMs = end - start;

      // Convert the difference to days
      const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

      return Math.floor(differenceInDays);
    },
    getEverythingFamilyArticle() {
      return {
        id: 0,
        parent_family_id: null,
        company_id: null,
        tva_id: 1,
        name: "everything",
        created_at: "2021-03-10",
        updated_at: "2021-03-10",
        migration_id: null,
        properties: [],
      };
    },
    updateFamilyArticleInLocalStorage(familyArticleId) {
      let articleFilters = JSON.parse(
        localStorage.getItem("ARTICLE_MODEL_FILTERS")
      );
      articleFilters["family"] = familyArticleId;
      localStorage.setItem(
        "ARTICLE_MODEL_FILTERS",
        JSON.stringify(articleFilters)
      );
    },
    returnErrorMessage(...args) {
      for (let index = 0; index < args.length; index++) {
        if (args[index] instanceof Array && args[index][0] == true) {
          return args[index][1];
        }
      }
    },
    generateRandomString(length) {
      let result = "";
      let characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    filterProperties(customFilters, properties) {
      let filteredProperties = [];
      filteredProperties = customFilters.filter((property) => {
        let found = false;
        properties.forEach((element) => {
          if (element == property.property_name) {
            found = true;
          }
        });
        return found ? property : false;
      });
      return filteredProperties;
    },

    filterPropertiesExceptProperties(customFilters, propertiesToExcept) {
      let filteredProperties = [];
      filteredProperties = customFilters.filter((property) => {
        let found = false;
        propertiesToExcept.forEach((element) => {
          if (element == property.property_name) {
            found = true;
          }
        });

        return found ? false : property;
      });
      return filteredProperties;
    },

    getCompanyPropertyFromLocalStorage(
      propertyNameToFind,
      valuetomatch = null
    ) {
      let companyProperties = JSON.parse(
        localStorage.getItem("company_properties")
      );
      let foundValue = null;
      if (companyProperties && companyProperties.length > 0) {
        companyProperties.forEach((companyProperty) => {
          if (
            companyProperty.company_setting_key.toLowerCase() ==
            propertyNameToFind.toLowerCase(companyProperty.company_setting_key)
          ) {
            if (valuetomatch) {
              if (companyProperty.value == valuetomatch)
                foundValue = companyProperty;
            } else {
              foundValue = companyProperty;
            }
          }
        });
        return foundValue;
      }
      return null;
    },

    clearAllSelection() {
      this.$store.commit("setSelectedItemsForTransformTo", null);
      this.$store.commit("setSelectedItemsForBulkPayment", null);
      this.$store.commit("setListingSelectionsFlatten", null);
      this.$store.commit("setListingSelections", null);
    },
    getDecimalNumber() {
      let decimal_precision =
        this.getCompanyPropertyFromLocalStorage("decimal_precision");
      return decimal_precision ? decimal_precision.value : 2;
    },
    fixDecimal(number, n) {
      if (n == undefined) {
        n = this.getDecimalNumber();
      }
      return (number ? parseFloat(number) : 0).toFixed(n);
    },
    getDatePartFromDateTimeStr(dtstr) {
      if (dtstr) {
        return dtstr.substr(0, 10);
      }
      return "";
    },
    makeZeros(decimalQuantity) {
      if (decimalQuantity && decimalQuantity > 0) {
        let zerostring = "0.";
        for (let count = 0; count < decimalQuantity; count++) {
          zerostring += "0";
        }
        return zerostring;
      }
      return "0";
    },
    convertToBoolean(val) {
      return val != 0 ? true : false;
    },
    getKeyFromCode(dataArray, code) {
      for (let index = 0; index < dataArray.length; index++) {
        if (dataArray[index].id == code) {
          return dataArray[index].key;
        }
      }
      return null;
    },
    degreesToRadians(degrees) {
      return (degrees * Math.PI) / 180;
    },
    distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
      let earthRadiusKm = 6371;

      let dLat = this.degreesToRadians(lat2 - lat1);
      let dLon = this.degreesToRadians(lon2 - lon1);

      lat1 = this.degreesToRadians(lat1);
      lat2 = this.degreesToRadians(lat2);

      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return earthRadiusKm * c;
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    translateKeys(data, field, section) {
      // If locale is not selected then choose languageId
      if (
        this.$i18n &&
        localStorage.getItem("languageId") &&
        localStorage.getItem("languageId") != this.$i18n.locale
      ) {
        this.$i18n.locale = localStorage.getItem("languageId");
      }
      if (typeof data === "object") {
        for (let index in data) {
          try {
            const key = `${section}.${data[index][field]}`;
            const translation = this.$te(key)
              ? this.$t(key)
              : data[index][field];
            data[index].text = translation;
          } catch (e) {
            data[index].text = data[index][field];
          }
        }
        return data;
      } else {
        const key = `${section}.${data}`;
        return this.$te(key) ? this.$t(key) : data;
      }
    },
    getPaymentPayedMethods(paymentMethods) {
      let payedMethodsCount = {};
      let payedMethods = "";

      // Count occurrences of each payment method
      paymentMethods.forEach((element) => {
        let key = element.payed_by.key;
        if (payedMethodsCount[key]) {
          payedMethodsCount[key]++;
        } else {
          payedMethodsCount[key] = 1;
        }
      });

      // Construct the result string
      for (let key in payedMethodsCount) {
        let methodName = this.$t("billings.payment_methods." + key);
        let count = payedMethodsCount[key];
        if (count > 1) {
          payedMethods += `${methodName} (${count}), `;
        } else {
          payedMethods += `${methodName}, `;
        }
      }

      return payedMethods.slice(0, -2);
    },
    encryptToMD5(data) {
      return CryptoJS.MD5(data);
    },
    resetBillType() {
      // this.billTypes.forEach((billType) => {
      //   if (billType.key == "purchase_order") {
      //     this.$store.commit("setBillType", billType);
      //   }
      // });
      let selectedBillType = this.selectDefaultBillTypeFromNavigationData();
      this.$store.commit("setBillType", selectedBillType);
    },
    resetExpensesType() {
      let selectedExpensesType =
        this.selectDefaultExpenseTypeFromNavigationData();
      this.$store.commit("setExpensesType", selectedExpensesType);
    },
    abortController() {
      // Get the map of abort controllers from the store
      let abortControllerMap = this.$store.state.abortControllerMap;

      // If the map is not empty, abort all previous requests
      if (JSON.stringify(abortControllerMap) != "{}") {
        for (let key in abortControllerMap) {
          abortControllerMap[key].abort();
        }
        // Clear the map after aborting all previous requests
        this.$store.state.abortControllerMap = {};
      }

      // Create a new abort controller for the latest request
      let controller = new AbortController();
      let randKey = Math.random().toString(36).substring(7);

      // Store the new controller in the map
      this.$store.state.abortControllerMap[randKey] = controller;

      // Return the signal of the new abort controller
      return controller.signal;
    },
    filterFirstError(errors) {
      if (errors === undefined) {
        return null;
      }

      for (let key in errors) {
        return errors[key][0];
      }
    },
    debounce(event, callback, delay = 1000) {
      event = event ? event : window.event;
      clearTimeout(this.debounceTimer);
      let value = event.target.value;
      this.debounceTimer = setTimeout(() => {
        let updatedValue = event.target.value;
        if (value === updatedValue) {
          callback();
        }
      }, delay);
    },
  },
};
