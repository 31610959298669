import Vue from "vue";
import VueRouter from "vue-router";
import { RESET_MODE } from "@/config";

import store from "../store/index";

import VerifyEmail from "@/Verification/VerifyEmail.vue";

function setComponent(baseFolder, componentName, dynamicSubFolder = null) {
  try {
    require("../views/" +
      baseFolder +
      "/" +
      dynamicSubFolder +
      "/" +
      componentName +
      "");
    if (dynamicSubFolder == null) {
      throw "err";
    }
    let componentImported = import(
      "../views/" +
        baseFolder +
        "/" +
        dynamicSubFolder +
        "/" +
        componentName +
        ""
    );
    return () => componentImported;
    // do stuff
  } catch (ex) {
    return () => import("../views/" + baseFolder + "/" + componentName + "");
  }
}

function getCompanyIdentifier() {
  let data = JSON.parse(localStorage.getItem("data"));
  let companyIdentifier = null;
  if (data) {
    companyIdentifier = data.userDetails.company.identifier;
  }
  return companyIdentifier;
}

Vue.use(VueRouter);

let routes = [
  // Before Login
  {
    path: "/login",
    name: "login",
    component: setComponent("Authentication", "Login", getCompanyIdentifier()),
    meta: {
      auth: false,
      title: "login",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: setComponent(
      "Authentication",
      "ForgotPassword",
      getCompanyIdentifier()
    ),
    meta: {
      auth: false,
      title: "forgot_password",
    },
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: setComponent(
      "Authentication",
      "ResetPassword",
      getCompanyIdentifier()
    ),
    meta: {
      auth: false,
      title: "reset_password",
    },
  },
  // After Login
  {
    path: "/",
    name: "Home",
    component: setComponent("Home", "Home", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "dashboard",
      parent: "company",
      tag: "dashboard",
    },
  },
  {
    path: "/pricingcalculator",
    name: "PricingCalculator",
    component: setComponent(
      "PricingCalculator",
      "PricingCalculator",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "pricing_calculator",
      parent: "company",
      tag: "dashboard",
    },
  },
  {
    path: "/pickups",
    name: "PickUps",
    component: setComponent(
      "PickupAddressMap",
      "PickupAddressMap",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "pickup_address_map",
      parent: "company",
      tag: "dashboard",
    },
  },
  {
    path: "/triggers",
    name: "Triggers",
    component: setComponent("Triggers", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "triggers",
      parent: "company",
    },
  },
  // Users Profile
  {
    path: "/user/profile",
    name: "UserProfile",
    component: setComponent("Users", "Profile", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "profile",
      parent: "company",
      tag: "user_profile",
      submit: "submit-user-profile",
    },
  },
  // Users Routes
  {
    path: "/users",
    name: "Users",
    component: setComponent("Users", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "users",
      parent: "company",
      tag: "users",
    },
  },
  {
    path: "/user-rights",
    name: "UserRights",
    component: setComponent("UserRights", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "user_rights",
      parent: "company",
      tag: "dashboard",
    },
  },
  {
    path: "/users/add",
    name: "NewUser",
    component: setComponent("Users", "User", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "new_user",
      submit: "submit-user",
      delete: "delete-user",
      parent: "company",
      tag: "users",
    },
  },
  {
    path: "/users/edit",
    name: "editUser",
    component: setComponent("Users", "User", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_user",
      submit: "submit-user",
      delete: "delete-user",
      parent: "company",
      tag: "users",
    },
  },
  // {
  //   path: "users/roles",
  //   name: "Roles",
  //   component: setComponent("Roles", "ListingV2", getCompanyIdentifier()),
  // },
  // Common
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/new-roles",
    name: "New-Roles",
    component: setComponent("Roles", "Role", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "new_user_roles",
      parent: "settings_nav",
      tag: "user_rights",
    },
  },
  //  Vehicles Route
  {
    path: "/vehicles",
    name: "Vehicles",
    component: setComponent("Vehicles", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "vehicles",
      parent: "company",
      tag: "vehicles",
    },
  },
  {
    path: "/vehicles/add",
    name: "AddVehicles",
    component: setComponent("Vehicles", "Vehicle", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "new_vehicle",
      parent: "company",
      submit: "submit-vehicle",
      tag: "vehicles",
    },
  },
  {
    path: "/vehicles/edit",
    name: "EditVehicles",
    component: setComponent("Vehicles", "Vehicle", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_vehicle",
      submit: "submit-vehicle",
      delete: "delete-vehicle",
      parent: "company",
      tag: "vehicles",
    },
  },
  // Rental Routes
  {
    path: "/rentals",
    name: "Rentals",
    component: setComponent("Rentals", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "rentals",
      parent: "company",
      tag: "rentals",
    },
  },
  {
    path: "/rentals/history",
    name: "rentals_history",
    component: setComponent("Rentals", "History", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "rentals",
      parent: "company",
      tag: "rentals",
    },
  },
  {
    path: "/rentals/add",
    name: "add_vehicle_rental",
    component: setComponent("Rentals", "Booking", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_vehicle_rental",
      parent: "company",
      tag: "rentals",
    },
  },
  {
    path: "/rentals/edit",
    name: "EditRentalsHistory",
    component: setComponent("Rentals", "Booking", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_rentals_history",
      parent: "company",
      tag: "rentals",
    },
  },
  {
    path: "/vehicles/history/add",
    name: "AddRentalsHistory",
    component: setComponent("Rentals", "Booking", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "rentals_history",
      parent: "company",
      tag: "rentals",
    },
  },

  // Client Routes
  {
    path: "/clients",
    name: "Clients",
    component: setComponent("Clients", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "clients",
      parent: "company",
      tag: "clients",
    },
  },
  {
    path: "/client/edit",
    name: "EditClient",
    component: setComponent("Clients", "Client", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_client",
      submit: "submit-client",
      delete: "delete-client",
      parent: "company",
      tag: "clients",
    },
  },
  {
    path: "/clients/add",
    name: "AddClient",
    component: setComponent("Clients", "Client", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_client",
      submit: "submit-client",
      delete: "delete-client",
      parent: "company",
      tag: "clients",
    },
  },
  // Articals Routes
  {
    path: "/articles",
    name: "Articles",
    component: setComponent("Articles", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "articles",
      parent: "company",
      tag: "articles",
    },
  },
  {
    path: "/articles/edit",
    name: "EditArticle",
    component: setComponent("Articles", "Article", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_article",
      submit: "submit-article",
      delete: "delete-article",
      parent: "company",
      tag: "articles",
    },
  },
  {
    path: "/articles/add",
    name: "AddArticle",
    component: setComponent("Articles", "Article", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_article",
      submit: "submit-article",
      delete: "delete-article",
      parent: "company",
      tag: "articles",
    },
  },
  // Family Client Routes
  {
    path: "/family-clients",
    name: "FamilyClients",
    component: setComponent("FamilyClients", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "family_client",
      parent: "company",
      tag: "family_clients",
    },
  },
  {
    path: "/family-clients/edit",
    name: "EditFamilyClient",
    component: setComponent(
      "FamilyClients",
      "FamilyClient",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "edit_family_client",
      submit: "submit-family-client",
      delete: "delete-family-client",
      parent: "company",
      tag: "family_clients",
    },
  },
  {
    path: "/family-clients/add",
    name: "AddFamilyClient",
    component: setComponent(
      "FamilyClients",
      "FamilyClient",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "add_family_client",
      submit: "submit-family-client",
      delete: "delete-family-client",
      parent: "company",
      tag: "family_clients",
    },
  },

  // Pricing Rules Routes
  {
    path: "/pricing-rules",
    name: "PricingRules",
    component: setComponent("PricingRule", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "pricing_rules",
      parent: "company",
      tag: "pricing_rules",
    },
  },
  {
    path: "/pricing-rules/edit",
    name: "EditPricingRule",
    component: setComponent(
      "PricingRule",
      "PricingRule",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "edit_pricing_rule",
      submit: "submit-pricing-rule",
      delete: "delete-pricing-rule",
      parent: "company",
      tag: "pricing_rules",
    },
  },
  {
    path: "/pricing-rules/add",
    name: "AddPricingRule",
    component: setComponent(
      "PricingRule",
      "PricingRule",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "add_pricing_rule",
      submit: "submit-pricing-rule",
      delete: "delete-pricing-rule",
      parent: "company",
      tag: "pricing_rules",
    },
  },

  // Stock Routes
  {
    path: "/stocks",
    name: "Stocks",
    component: setComponent("Stocks", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "stocks",
      parent: "company",
      tag: "stocks",
    },
  },
  {
    path: "/stocks/edit",
    name: "EditStock",
    component: setComponent("Stocks", "Stock", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_stock",
      submit: "submit-stock",
      delete: "delete-stock",
      parent: "company",
      tag: "stocks",
    },
  },
  {
    path: "/stocks/add",
    name: "AddStock",
    component: setComponent("Stocks", "Stock", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_stock",
      submit: "submit-stock",
      delete: "delete-stock",
      parent: "company",
      tag: "stocks",
    },
  },
  // Billing Routes
  {
    path: "/billing",
    name: "BillingListing",
    component: setComponent("Billing", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "billing",
      parent: "company",
      tag: "billing",
    },
  },
  {
    path: "/billing/edit",
    name: "EditBilling",
    component: setComponent("Billing", "Billing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_billing",
      submit: "submit-billing",
      delete: "delete-billing",
      parent: "company",
      tag: "billing",
    },
  },
  {
    path: "/billing/add",
    name: "AddBilling",
    component: setComponent("Billing", "Billing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_billing",
      submit: "submit-billing",
      delete: "delete-billing",
      parent: "company",
      tag: "billing",
    },
  },
  // Expedition Routes
  {
    path: "/expedition",
    name: "ExpeditionListing",
    component: setComponent("Expedition", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "expedition",
      parent: "company",
      tag: "expedition",
    },
  },
  {
    path: "/expedition/edit",
    name: "EditExpedition",
    component: setComponent("Expedition", "Expedition", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_expedition",
      submit: "submit-expedition",
      delete: "delete-expedition",
      parent: "company",
      tag: "expedition",
    },
  },
  {
    path: "/expedition/add",
    name: "AddExpedition",
    component: setComponent("Expedition", "Expedition", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_expedition",
      submit: "submit-expedition",
      delete: "delete-expedition",
      parent: "company",
      tag: "expedition",
    },
  },
  // Expenses Routes
  {
    path: "/expenses",
    name: "ExpensesListing",
    component: setComponent("Expenses", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "expenses",
      parent: "company",
      tag: "expenses",
      delete: "delete-expenses",
    },
  },
  {
    path: "/expenses/edit",
    name: "EditExpenses",
    component: setComponent("Expenses", "Expenses", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_expenses",
      submit: "submit-expenses",
      delete: "delete-expenses",
      parent: "company",
      tag: "expenses",
    },
  },
  {
    path: "/expenses/add",
    name: "AddExpenses",
    component: setComponent("Expenses", "Expenses", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_expenses",
      submit: "submit-expenses",
      delete: "delete-expenses",
      parent: "company",
      tag: "expenses",
    },
  },
  // Recurrence Routes
  {
    path: "/recurrence_document",
    name: "RecurrenceDocumentListing",
    component: setComponent("Recurrence", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "recurrence",
      parent: "company",
      tag: "recurrence_document",
    },
  },
  {
    path: "/recurrence_document/edit",
    name: "EditRecurrenceDocument",
    component: setComponent("Recurrence", "Recurrence", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_recurrence_document",
      submit: "submit-recurrence",
      delete: "delete-recurrence",
      parent: "company",
      tag: "recurrence_document",
    },
  },
  {
    path: "/recurrence_document/add",
    name: "AddRecurrence",
    component: setComponent("Recurrence", "Recurrence", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_recurrence",
      submit: "submit-recurrence",
      delete: "delete-recurrence",
      parent: "company",
      tag: "recurrence_document",
    },
  },
  // Client Recurrence Routes
  {
    path: "/client_recurrence_document",
    name: "ClientRecurrenceListing",
    component: setComponent(
      "ClientRecurrence",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "recurrence",
      parent: "company",
      tag: "recurrence_document",
    },
  },
  {
    path: "/client_recurrence_document/edit",
    name: "EditClientRecurrence",
    component: setComponent(
      "ClientRecurrence",
      "ClientRecurrence",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "edit_recurrence",
      submit: "submit-recurrence",
      delete: "delete-recurrence",
      parent: "company",
      tag: "recurrence_document",
    },
  },
  {
    path: "/client_recurrence_document/add",
    name: "AddClientRecurrence",
    component: setComponent(
      "ClientRecurrence",
      "ClientRecurrence",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "add_recurrence",
      submit: "submit-recurrence",
      delete: "delete-recurrence",
      parent: "company",
      tag: "recurrence_document",
    },
  },
  //  Reception Route
  {
    path: "/receptions/list",
    name: "Receptions",
    component: setComponent("Reception", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "receptions",
      parent: "reception",
      tag: "receptions",
    },
  },
  {
    path: "/receptions/list/add",
    name: "ReceptionsAdd",
    component: setComponent("Reception", "Reception", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "scan_receptions",
      parent: "reception",
      tag: "receptions",
    },
  },
  // Reception Verify
  {
    path: "/receptions/verify",
    name: "Receptions",
    component: setComponent(
      "ReceptionVerification",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "receptions_verify",
      parent: "reception",
      tag: "receptions",
    },
  },
  {
    path: "/receptions/verify/edit",
    name: "ReceptionsAdd",
    component: setComponent(
      "ReceptionVerification",
      "ReceptionVerification",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "inject_reception",
      parent: "reception",
      tag: "receptions",
    },
  },
  // Calendar Route
  {
    path: "/scheduler",
    name: "Calendar",
    component: setComponent("Scheduler", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "calendar",
      parent: "calendar",
      tag: "scheduler",
    },
  },
  {
    path: "/scheduler/add",
    name: "AddScheduler",
    component: setComponent("Scheduler", "Scheduler", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "create_appointment",
      parent: "calendar",
      submit: "submit-scheduler",
      tag: "scheduler",
    },
  },
  {
    path: "/scheduler/edit",
    name: "EditScheduler",
    component: setComponent("Scheduler", "Scheduler", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_appointment",
      parent: "calendar",
      submit: "submit-scheduler",
      delete: "delete-scheduler",
      tag: "scheduler",
    },
  },
  // Report Route
  {
    path: "/report",
    name: "Report",
    component: setComponent("Report", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "report",
      parent: "report",
      tag: "report",
    },
  },
  // Company Setting Routes
  {
    path: "/company-setting",
    name: "CompanySetting",
    component: setComponent(
      "CompanySetting",
      "CompanySetting",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "company_setting",
      submit: "submit-company-setting",
      parent: "company",
      tag: "company_settings",
    },
  },

  // Family Article Routes
  {
    path: "/family-articles",
    name: "FamilyArticles",
    component: setComponent(
      "FamilyArticles",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "family_article",
      parent: "company",
      tag: "family_articles",
    },
  },
  {
    path: "/family-articles/edit",
    name: "EditFamilyArticles",
    component: setComponent(
      "FamilyArticles",
      "FamilyArticle",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "edit_family_article",
      submit: "submit-family-article",
      delete: "delete-family-article",
      parent: "company",
      tag: "family_articles",
    },
  },
  {
    path: "/family-articles/add",
    name: "AddFamilyArticles",
    component: setComponent(
      "FamilyArticles",
      "FamilyArticle",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "add_family_article",
      submit: "submit-family-article",
      delete: "delete-family-article",
      parent: "company",
      tag: "family_articles",
    },
  },

  // Brands Routes
  {
    path: "/brands",
    name: "Brands",
    component: setComponent("Brand", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "brands",
      parent: "company",
      tag: "brands",
    },
  },
  {
    path: "/brands/edit",
    name: "EditBrands",
    component: setComponent("Brand", "Brand", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_brand",
      submit: "submit-brand",
      delete: "delete-brand",
      parent: "company",
      tag: "brands",
    },
  },
  {
    path: "/brands/add",
    name: "AddBrands",
    component: setComponent("Brand", "Brand", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_brand",
      submit: "submit-brand",
      delete: "delete-brand",
      parent: "company",
      tag: "brands",
    },
  },
  // tyreHotel Routes
  {
    path: "/depot",
    name: "TyreHotel",
    component: setComponent("TyreHotel", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "tyre_depot",
      submit: "submit-brand",
      delete: "delete-brand",
      parent: "company",
      tag: "tyre_depot",
    },
  },
  {
    path: "/depot/bulk-operation",
    name: "TyreHotelBulkOperation",
    component: setComponent("TyreHotelBulk", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "tyre_depot_bulk_operations",
      parent: "company",
      tag: "tyre_depot",
    },
  },
  {
    path: "/depot/bulk-operation/details",
    name: "TyreHotelBulkOperationDetails",
    component: setComponent(
      "TyreHotelBulk",
      "BulkOperation",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "tyre_depot_bulk_operation_details",
      parent: "company",
      tag: "tyre_depot",
    },
  },
  // Article Pricing Routes
  {
    path: "/article-pricing",
    name: "ArticlePricing",
    component: setComponent(
      "ArticlePricing",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "article_pricing",
      parent: "company",
      tag: "article_pricing",
    },
  },

  // Supplier Routes
  {
    path: "/suppliers",
    name: "Supplier",
    component: setComponent("Suppliers", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "suppliers",
      parent: "company",
      tag: "suppliers",
    },
  },
  {
    path: "/suppliers/edit",
    name: "EditSuppliers",
    component: setComponent("Suppliers", "Supplier", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "edit_supplier",
      submit: "submit-supplier",
      delete: "delete-supplier",
      parent: "company",
      tag: "suppliers",
    },
  },
  {
    path: "/brands/add",
    name: "AddBrands",
    component: setComponent("Brand", "Brand", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "add_brand",
      submit: "submit-brand",
      delete: "delete-brand",
      parent: "company",
      tag: "brands",
    },
  },
  {
    path: "/emails",
    name: "EmailListing",
    component: setComponent("Emails", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "email_listing",
      parent: "company",
    },
  },
  // Employee Clocking
  {
    path: "/clocking",
    name: "EmployeeClockingListing",
    component: setComponent("Clocking", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "employee_clocking",
      parent: "company",
    },
  },
  {
    path: "/clocking_rebuild",
    name: "EmployeeClockingListing",
    component: setComponent("Clocking", "ListingOld", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "employee_clocking",
      parent: "company",
    },
  },
  {
    path: "/employees",
    name: "EmployeeListing",
    component: setComponent(
      "Clocking/Employees",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employees",
      parent: "company",
    },
  },
  {
    path: "/employee-contracts",
    name: "EmployeeContractListing",
    component: setComponent(
      "Clocking/EmployeeContracts",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employee_contracts",
      parent: "company",
    },
  },
  {
    path: "/requests",
    name: "EmployeeRequestsListing",
    component: setComponent(
      "Clocking",
      "EmployeeRequestListing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employee_requests",
      parent: "company",
    },
  },
  {
    path: "/employment-contracts",
    name: "EmploymentContractsListing",
    component: setComponent(
      "Clocking/EmploymentContracts",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employment_contracts",
      parent: "company",
    },
  },
  {
    path: "/emails",
    name: "EmailListing",
    component: setComponent("Emails", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "email_listing",
      parent: "company",
    },
  },
  // Employee Clocking
  {
    path: "/clocking",
    name: "EmployeeClockingListing",
    component: setComponent("Clocking", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "employee_clocking",
      parent: "company",
    },
  },
  {
    path: "/employees",
    name: "EmployeeListing",
    component: setComponent(
      "Clocking/Employees",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employees",
      parent: "company",
    },
  },
  {
    path: "/employee-contracts",
    name: "EmployeeContractListing",
    component: setComponent(
      "Clocking/EmployeeContracts",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employee_contracts",
      parent: "company",
    },
  },
  {
    path: "/requests",
    name: "EmployeeRequestsListing",
    component: setComponent(
      "Clocking",
      "EmployeeRequestListing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employee_requests",
      parent: "company",
    },
  },
  {
    path: "/employment-contracts",
    name: "EmploymentContractsListing",
    component: setComponent(
      "Clocking/EmploymentContracts",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employment_contracts",
      parent: "company",
    },
  },
  {
    path: "/working-shifts",
    name: "WorkingShiftListing",
    component: setComponent(
      "Clocking/WorkingShift",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employment_working_shifts",
      parent: "company",
    },
  },
  {
    path: "/employee-working-shifts",
    name: "EmployeeWorkingShiftListing",
    component: setComponent(
      "Clocking/EmployeeWorkingShifts",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "employee_working_shifts",
      parent: "company",
    },
  },
  // -- Company Supplier Connections
  {
    path: "/supplier-connections",
    name: "CompanySupplierConnections",
    component: setComponent(
      "CompanySupplierConnection",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "company_supplier_connections",
      parent: "company",
    },
  },
  {
    path: "/supplier-connections/add",
    name: "CompanySupplierConnectionsAdd",
    component: setComponent(
      "CompanySupplierConnection",
      "SupplierConnection",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "company_supplier_connections",
      parent: "company",
    },
  },
  {
    path: "/order-reminder-schema",
    name: "OrderReminderSchemaListing",
    component: setComponent(
      "OrderReminderSchema",
      "Listing",
      getCompanyIdentifier()
    ),
    meta: {
      auth: true,
      title: "order_reminder_schema",
      parent: "company",
    },
  },
  {
    path: "/translations",
    name: "TranslationListing",
    component: setComponent("Translations", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "translation_listing",
      submit: "submit-translation-listing",
      parent: "company",
    },
  },
  {
    path: "/cash-register",
    name: "CashRegisterListing",
    component: setComponent("CashRegister", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "cash_register_listing",
      parent: "company",
    },
  },
  {
    path: "/address-book",
    name: "AddressBookListing",
    component: setComponent("AddressBook", "Listing", getCompanyIdentifier()),
    meta: {
      auth: true,
      title: "address_book_listing",
      parent: "company",
    },
  },
  {
    path: "/email_verification/:id",
    name: "VerifyEmailAddress",
    component: VerifyEmail,
    meta: {
      auth: false,
      title: "verify_email_address",
      parent: "company",
    },
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
];

const router = new VueRouter({
  routes: routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (
    (!localStorage.getItem("accessToken") ||
      !localStorage.getItem("applicationResources")) &&
    to.name != "login"
  ) {
    // this will prevent the page from mount
    localStorage.clear();
    router.push("login");
  }
  if (localStorage.getItem("accessToken")) {
    if (
      to.meta &&
      to.meta.tag &&
      !JSON.parse(localStorage.getItem("applicationResources")).includes(
        to.meta.tag
      ) &&
      to.path != from.path
    ) {
      router.push("dashboard");
    }

    // reset validation
    store.commit("setValidation", false);

    // reset reception data
    store.commit("setReceptionDataForInjecting", null);
    store.commit("setReceptionRecItemsForInjecting", null);

    // Reset store loading
    store.commit("setLoading", false);

    // Reset Form Mode
    store.commit("setFormMode", RESET_MODE);

    // Reset Depot Mode
    store.commit("setShowDepotButtons", 0);

    // Reset Attachment Count
    store.commit("setAttachmentCounts", 0);

    // Reset Selected Items For Payments
    store.commit("setSelectedItemsForBulkPayment", null);

    // Reset Selected Items For Convert
    store.commit("setSelectedItemsForTransformTo", null);

    // Reset Selection Items
    store.commit("setListingSelectionsFlatten", null);

    // Reset Vehicle Data
    store.commit("setVehicleDataForAppBar", null);

    // Reset Submit Loading Data
    store.commit("setTopSubmitLoading", false);

    // Reset Current Page Data
    store.commit("setCurrentPageData", null);

    if (from.name !== null) {
      localStorage.setItem("LS_ROUTE_KEY", from.name);
    }
    if (to.name !== null) {
      localStorage.setItem("NT_ROUTE_KEY", to.name);
    }

    if (!to.path.includes("edit")) {
      store.commit("seteditId", null); // if route does not include edit then setEditId Null
    }
    if (!to.path.includes("articles")) {
      localStorage.removeItem("ARTICLE_FILTERS");
    }

    // When we make a bill from expedition then donot remove the expedition id for making a bill for that expedition
    if (
      !(to.path != "/billing/add") ||
      !(to.path != "/billing/edit") ||
      !(to.path != "/expenses/add") ||
      !(to.path != "/expenses/edit")
    ) {
      //
    } else {
      store.commit("setSelectedExpeditionIdsForBilling", null); // Otherwise set it as null
    }

    localStorage.removeItem("ARTICLE_MODEL_FILTERS");

    // Reset Pdf Viewer Properties to null
    store.commit("setPreviewUrl", null);
    store.commit("setPreviewMimeType", null);
    store.commit("setIsShowFullScreen", true);
    store.commit("setOpenInNewWindow", false);
  }
  return next();
});

router.afterEach((to, from) => {
  if (to.name == "AddScheduler") {
    store.state.applySelectedArticlePendingAppointmentKey = Math.floor(
      Math.random() * 1000000000
    );
    store.dispatch("forceReloadComponent");
  }

  if (to.name == "AddClient") {
    store.dispatch("forceReloadComponent");
  }

  if (from.name === "EditVehicles") {
    let intervalTimer = parseInt(sessionStorage.getItem("intervalTimer"));
    if (intervalTimer) {
      clearInterval(intervalTimer);
      sessionStorage.removeItem("intervalTimer");
    }
  }
});

export default router;
