<template>
  <v-card elevation="0" style="width: 100%" class="mt-2">
    <v-row v-if="checkTag('clients')">
      <!-- Entity Type -->
      <v-col class="" cols="12" :sm="3">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12" :sm="showEntityType ? 6 : 12">
            <v-autocomplete
              class="to-upper"
              :autocomplete="autocompleteValueLocal"
              role="presentation"
              :disabled="isEditingAllowed"
              v-model="familyClient"
              :items="familyClients"
              :label="$t('pricing_rule.family_client')"
              item-value="id"
              item-text="name"
              menu-props="auto"
              ref="family_client"
              v-on:keydown.enter.prevent=""
              @change="$emit('update:comp_familyClient', familyClient);"
              :error-messages="
                $store.state.validation && !$v.familyClient.required
                  ? [this.$t('billings.text_messages.family_client_required')]
                  : []
              "
              @input="changedFamilyClient"
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-no-data
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12" sm="6" v-if="showEntityType">
            <v-select
              class="to-upper"
              :disabled="isEditingAllowed"
              v-model="entityType"
              :items="entityTypeSelectItems"
              :label="$t('billings.entity_type')"
              item-text="text"
              item-value="id"
              return-object
              v-on:keydown.enter.prevent=""
              @change="
                $emit('update:comp_entityType', entityType);
                fixDenominationIfDenominationIsHidden();
              "
              :outlined="is_boxes"
              :dense="is_boxes"
              hide-details
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <!-- Name -->
      <v-col
        style="display: flex"
        class="pt-0 pb-0"
        cols="12"
        :sm="isCompanyEntitySelected ? 4 : 5"
      >
        <v-icon
          style="display: inline-flex"
          readonly
          v-if="isEditingAllowedBeforeBill"
          color="primary"
          @click.native="editClient"
          >mdi-pencil
        </v-icon>

        <v-tooltip right v-if="clientIsBlocked">
          <span>{{ $t("clients.client_blocked_full") }}</span>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-1" v-on="on" v-bind="attrs" color="red"
              >mdi-account-alert
            </v-icon>
          </template>
        </v-tooltip>
        <v-combobox
          @click.native="isEditingAllowed ? editClient : ''"
          class="to-upper"
          :autocomplete="autocompleteValueLocal"
          role="presentation"
          ref="clientName"
          :disabled="isEditingAllowed"
          v-model="company_client_name"
          :items="company_client_names"
          :error-messages="
            $store.state.validation && !$v.company_client_name.required
              ? [
                  this.$t(
                    'billings.text_messages.client_or_company_name_required'
                  ),
                ]
              : []
          "
          item-value="id"
          item-text="name"
          :label="
            isCompanyEntitySelected
              ? $t('billings.comp_name')
              : $t('billings.family_name')
          "
          required
          @keyup="
            $emit('update:comp_company_client_name', company_client_name);
            $emit('update:comp_search_client', search_client);
            isSameCountryTva();
          "
          @change="
            $emit('update:comp_company_client_name', company_client_name);
            changedFamilyClient();
          "
          append-icon="mdi-close"
          v-on:keydown="handleInput($event, 'everything', 50)"
          :search-input.sync="search_client"
          @input.native="updatedSearchClient"
          no-filter
          v-on:keydown.enter.prevent="fetchClientsListDebounced()"
          return-object
          :outlined="is_boxes"
          :dense="is_boxes"
          hide-details
        >
          <template slot="item" slot-scope="{ item }">
            <v-list-item-content>
              <p class="client_name">{{ item.name }} {{ item.first_name }}</p>
              <p class="client_detail" v-if="item.representative_company_id">
                Company
              </p>
              <p class="client_detail" v-else>Private</p>
              <p class="client_detail">{{ item.email }}</p>
            </v-list-item-content>
          </template>
          <template slot="append">
            <v-icon @click="onResetPageClient()">mdi-close</v-icon>
          </template>
        </v-combobox>
        <v-btn
          class="mt-6 ml-2"
          @click="showAttentionMessage()"
          x-small
          color="error"
          v-if="
            company_client_name &&
            typeof company_client_name == 'object' &&
            company_client_name.note
          "
        >
          <v-icon x-small>mdi-information</v-icon>
          {{ $t("clients.attention") }}
        </v-btn>
      </v-col>
      <!-- First Name -->
      <v-col
        v-if="!isCompanyEntitySelected"
        class="pt-0 pb-0"
        cols="12"
        :sm="4"
      >
        <v-text-field
          class="to-upper"
          :autocomplete="autocompleteValueLocal"
          role="presentation"
          ref="first_name"
          :disabled="isEditingAllowed"
          v-model="company_client_first_name"
          :label="$t('billings.client_first_name')"
          required
          :hide-details="false"
          @keyup="
            $emit(
              'update:comp_company_client_first_name',
              company_client_first_name
            )
          "
          @change="
            $emit(
              'update:comp_company_client_first_name',
              company_client_first_name
            )
          "
          v-on:keydown="handleInput($event, 'everything', 50)"
          no-filter
          v-on:keydown.enter.prevent=""
        >
        </v-text-field>
      </v-col>
      <!-- Denomination -->
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="1"
        v-if="isCompanyEntitySelected && !hideDenomination"
      >
        <v-select
          class="to-upper"
          :disabled="isEditingAllowed"
          v-model="denomination"
          :items="denominations"
          item-value="id"
          item-text="text"
          :label="$t('billings.denomination')"
          v-on:keydown.enter.prevent=""
          @change="$emit('update:comp_denomination', denomination)"
          :outlined="is_boxes"
          :dense="is_boxes"
          hide-details
        ></v-select>
      </v-col>
      <!-- TVA -->
      <v-col
        class="pt-0 pb-0"
        cols="12"
        :sm="hideDenomination ? 3 : 2"
        v-if="isCompanyEntitySelected && !isCompanyLeaser"
      >
        <div @keypress="isSameCountryTva">
          <TvaInput
            v-model="tva"
            @input.native="isSameCountryTva"
            :label="$t('billings.tva_number')"
            :disabled="isEditingAllowed"
            :error-messages="
              $store.state.validation && !$v.tva.required
                ? [this.$t('billings.text_messages.tva_required')]
                : []
            "
            :highlight="highlightTva"
          ></TvaInput>
        </div>
      </v-col>
      <!-- Taxation Reason -->
      <v-col
        class="pt-0 pb-0"
        cols="12"
        sm="2"
        v-if="isCompanyEntitySelected && !isCompanyLeaser"
      >
        <v-select
          :autocomplete="autocompleteValueLocal"
          role="presentation"
          v-model="taxation_reason_id"
          :items="taxationReasons"
          return-object
          class="to-upper"
          item-value="id"
          item-text="text"
          :label="$t('clients.taxation_reason')"
          :outlined="is_boxes"
          :dense="is_boxes"
          :background-color="getTaxationReasonBackgroundColor(taxation_reason_id)"
        ></v-select>
      </v-col>
      <!-- Show If Company Leaser -->
      <template v-if="isCompanyLeaser">
        <!-- Email -->
        <v-col class="pt-0 pb-0" sols>
          <EmailInput
            v-model="company_client_email"
            :email_position="1"
            :client_id="clientId"
            :label="$t('billings.email')"
            :error_messages="
              $store.state.validation && !$v.company_client_email.email
                ? [this.$t('billings.text_messages.invalid_email')]
                : []
            "
            @keyup="
              $emit('update:comp_company_client_email', company_client_email)
            "
          ></EmailInput>
        </v-col>
        <!-- GSM -->
        <v-col class="pt-0 pb-0">
          <vue-tel-input-vuetify
            :disabledFetchingCountry="true"
            :preferredCountries="preferredCountry"
            :filterCountries="onlyCountries"
            validCharactersOnly
            :label="$t('billings.gsm')"
            :inputOptions="{ showDialCode: true, tabindex: 0 }"
            :mode="'international'"
            v-model="company_client_gsm"
            @onInput="
              $emit('update:comp_company_client_gsm', company_client_gsm)
            "
          ></vue-tel-input-vuetify>
        </v-col>
        <!-- Phone -->
        <v-col class="pt-0 pb-0">
          <vue-tel-input-vuetify
            :disabledFetchingCountry="true"
            :preferredCountries="preferredCountry"
            :filterCountries="onlyCountries"
            validCharactersOnly
            :label="$t('billings.phone')"
            :inputOptions="{ showDialCode: true, tabindex: 0 }"
            :mode="'international'"
            v-model="company_client_phone"
            @onInput="
              $emit('update:comp_company_client_phone', company_client_phone)
            "
          ></vue-tel-input-vuetify>
        </v-col>
      </template>
    </v-row>

    <!-- First Row of E-Mail, GSM, Phone -->
    <v-row v-if="!isCompanyLeaser">
      <!-- Language -->
      <v-col class="pt-0 pb-0" v-if="showLanguage">
        <v-autocomplete
          class="to-upper"
          :autocomplete="autocompleteValueLocal"
          role="presentation"
          flat
          :items="languages"
          v-model="language"
          :label="$t('billings.languages')"
          @change="$emit('update:comp_company_client_language', language)"
          item-value="id"
          item-text="text"
          v-on:keydown.enter.prevent=""
          :outlined="is_boxes"
          :dense="is_boxes"
          hide-no-data
          hide-details
        ></v-autocomplete>
      </v-col>
      <!-- Email -->
      <v-col class="pt-0 pb-0">
        <EmailInput
          v-model="company_client_email"
          :email_position="1"
          :client_id="clientId"
          :label="$t('billings.email')"
          :error_messages="
            $store.state.validation && !$v.company_client_email.email
              ? [this.$t('billings.text_messages.invalid_email')]
              : []
          "
          @keyup="
            $emit('update:comp_company_client_email', company_client_email)
          "
        ></EmailInput>
      </v-col>
      <!-- GSM -->
      <v-col class="pt-0 pb-0">
        <vue-tel-input-vuetify
          :disabledFetchingCountry="true"
          :preferredCountries="preferredCountry"
          :filterCountries="onlyCountries"
          validCharactersOnly
          :label="$t('billings.gsm')"
          :inputOptions="{ showDialCode: true, tabindex: 0 }"
          :mode="'international'"
          v-model="company_client_gsm"
          @onInput="$emit('update:comp_company_client_gsm', company_client_gsm)"
        ></vue-tel-input-vuetify>
      </v-col>
      <!-- Phone -->
      <v-col class="pt-0 pb-0">
        <vue-tel-input-vuetify
          :disabledFetchingCountry="true"
          :preferredCountries="preferredCountry"
          :filterCountries="onlyCountries"
          validCharactersOnly
          :label="$t('billings.phone')"
          :inputOptions="{ showDialCode: true, tabindex: 0 }"
          :mode="'international'"
          v-model="company_client_phone"
          @onInput="
            $emit('update:comp_company_client_phone', company_client_phone)
          "
        ></vue-tel-input-vuetify>
      </v-col>
      <!-- IBAN -->
      <v-col class="pt-0 pb-0" v-if="showIban">
        <v-combobox
          :autocomplete="autocompleteValueLocal"
          v-model="iban"
          :items="ibans"
          append-icon="mdi-close"
          item-text="bank_account"
          class="to-upper"
          item-value="id"
          return-object
          :label="$t('billings.iban')"
          :outlined="is_boxes"
          :dense="is_boxes"
          @focus="company_client_name && !iban ? '' // saveClientAndAddBank()
           : ''"
        >
          <template slot="append">
            <v-icon
              v-if="iban && typeof iban == 'object'"
              class="mr-1"
              :size="18"
              @click="copyText(iban.bank_account)"
            >
              mdi-content-copy
            </v-icon>
            <v-icon
              v-if="company_client_name && !iban"
              @click="saveClientAndAddBank"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="iban" @click="editBank(iban)">mdi-pencil</v-icon>
            <v-icon v-if="iban" @click="removeBank()">mdi-close</v-icon>
            <!-- <v-icon v-if="!member && client" @click="addMember()">mdi-plus</v-icon> -->
          </template>
          <template slot="item" slot-scope="{ item }">
            <v-list-item-content @click="iban = item">
              <p class="iban_name">
                {{ (item.bank_account ? item.bank_account : "").toUpperCase() }}
              </p>
              <p class="iban_detail">
                <template v-if="item.bank_name">
                  {{ (item.bank_name ? item.bank_name : "").toUpperCase() }}
                </template>
                <template v-if="item.branch_name">
                  -
                  {{ (item.branch_name ? item.branch_name : "").toUpperCase() }}
                </template>
                <template v-if="item.swift_code">
                  -
                  {{ (item.swift_code ? item.swift_code : "").toUpperCase() }}
                </template>
              </p>
            </v-list-item-content>
          </template>

          <template slot="selection" slot-scope="{ item }">
            <v-list-item-content>
              <template v-if="item && typeof item == 'object'">
                {{
                  (item.bank_account
                    ? splitInParts(item.bank_account)
                    : ""
                  ).toUpperCase()
                }}
              </template>
            </v-list-item-content>
          </template>
        </v-combobox>
      </v-col>

      <v-tooltip
        bottom
        v-if="(isAnythingFilledForFirstRow && !showSecondRow) || !showSecondRow"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            class="mt-4"
            v-on="on"
            v-if="
              (isAnythingFilledForFirstRow && !showSecondRow) || !showSecondRow
            "
            @click="showSecondRow = true"
          >
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span> {{ $t("clients.add_another_contact_person") }} </span>
      </v-tooltip>
    </v-row>

    <!-- Second Row of E-Mail, GSM, Phone -->
    <v-row v-if="!isCompanyLeaser && showSecondRow">
      <!-- Name -->
      <v-col class="pt-0 pb-0" cols="12" :sm="4">
        <v-text-field
          class="to-upper"
          :autocomplete="autocompleteValueLocal"
          role="presentation"
          ref="name"
          v-model="company_client_contact_name_2"
          :label="$t('billings.company_contact_name_2')"
          required
          :hide-details="false"
          @keyup="
            $emit(
              'update:comp_company_client_contact_name_2',
              company_client_contact_name_2
            )
          "
          @change="
            $emit(
              'update:comp_company_client_contact_name_2',
              company_client_contact_name_2
            )
          "
          v-on:keydown="handleInput($event, 'everything', 50)"
          no-filter
          v-on:keydown.enter.prevent=""
        >
        </v-text-field>
      </v-col>
      <!-- Email -->
      <v-col class="pt-0 pb-0">
        <EmailInput
          v-model="company_client_email_2"
          :email_position="1"
          :client_id="clientId"
          :label="$t('billings.email_2')"
          :error_messages="
            $store.state.validation && !$v.company_client_email_2.email
              ? [this.$t('billings.text_messages.invalid_email')]
              : []
          "
          @keyup="
            $emit('update:comp_company_client_email_2', company_client_email_2)
          "
          :show_icon_for_verification="false"
        ></EmailInput>
      </v-col>
      <!-- GSM -->
      <v-col class="pt-0 pb-0">
        <vue-tel-input-vuetify
          :disabledFetchingCountry="true"
          :preferredCountries="preferredCountry"
          :filterCountries="onlyCountries"
          validCharactersOnly
          :label="$t('billings.gsm_2')"
          :inputOptions="{ showDialCode: true, tabindex: 0 }"
          :mode="'international'"
          v-model="company_client_gsm_2"
          @onInput="
            $emit('update:comp_company_client_gsm_2', company_client_gsm_2)
          "
        ></vue-tel-input-vuetify>
      </v-col>
      <!-- Phone -->
      <v-col class="pt-0 pb-0">
        <vue-tel-input-vuetify
          :disabledFetchingCountry="true"
          :preferredCountries="preferredCountry"
          :filterCountries="onlyCountries"
          validCharactersOnly
          :label="$t('billings.phone_2')"
          :inputOptions="{ showDialCode: true, tabindex: 0 }"
          :mode="'international'"
          v-model="company_client_phone_2"
          @onInput="
            $emit('update:comp_company_client_phone_2', company_client_phone_2)
          "
        ></vue-tel-input-vuetify>
      </v-col>
      <v-tooltip
        bottom
        v-if="(isAnythingFilledForThirdRow && !showThirdRow) || !showThirdRow"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="mt-4"
            v-if="
              (isAnythingFilledForThirdRow && !showThirdRow) || !showThirdRow
            "
            @click="showThirdRow = true"
          >
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span> {{ $t("clients.add_another_contact_person") }} </span>
      </v-tooltip>
      <v-tooltip
        bottom
        v-if="(isAnythingFilledForThirdRow && !showThirdRow) || !showThirdRow"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            class="mt-4"
            v-on="on"
            v-if="
              (isAnythingFilledForThirdRow && !showThirdRow) || !showThirdRow
            "
            @click="showSecondRow = false"
          >
            <v-icon color="primary">mdi-minus</v-icon>
          </v-btn>
        </template>
        <span> {{ $t("clients.remove_contact_person") }} </span>
      </v-tooltip>
    </v-row>

    <!-- Third Row of E-Mail, GSM, Phone -->
    <v-row v-if="!isCompanyLeaser && showThirdRow">
      <!-- Name -->
      <v-col class="pt-0 pb-0" cols="12" :sm="4">
        <v-text-field
          class="to-upper"
          :autocomplete="autocompleteValueLocal"
          role="presentation"
          ref="name"
          v-model="company_client_contact_name_3"
          :label="$t('billings.company_contact_name_3')"
          required
          :hide-details="false"
          @keyup="
            $emit(
              'update:comp_company_client_contact_name_3',
              company_client_contact_name_3
            )
          "
          @change="
            $emit(
              'update:comp_company_client_contact_name_3',
              company_client_contact_name_3
            )
          "
          v-on:keydown="handleInput($event, 'everything', 50)"
          no-filter
          v-on:keydown.enter.prevent=""
        >
        </v-text-field>
      </v-col>
      <!-- Email -->
      <v-col class="pt-0 pb-0">
        <EmailInput
          v-model="company_client_email_3"
          :email_position="1"
          :client_id="clientId"
          :label="$t('billings.email_3')"
          :error_messages="
            $store.state.validation && !$v.company_client_email_3.email
              ? [this.$t('billings.text_messages.invalid_email')]
              : []
          "
          @keyup="
            $emit('update:comp_company_client_email_3', company_client_email_3)
          "
          :show_icon_for_verification="false"
        ></EmailInput>
      </v-col>
      <!-- GSM -->
      <v-col class="pt-0 pb-0">
        <vue-tel-input-vuetify
          :disabledFetchingCountry="true"
          :preferredCountries="preferredCountry"
          :filterCountries="onlyCountries"
          validCharactersOnly
          :label="$t('billings.gsm_3')"
          :inputOptions="{ showDialCode: true, tabindex: 0 }"
          :mode="'international'"
          v-model="company_client_gsm_3"
          @onInput="
            $emit('update:comp_company_client_gsm_3', company_client_gsm_3)
          "
        ></vue-tel-input-vuetify>
      </v-col>
      <!-- Phone -->
      <v-col class="pt-0 pb-0">
        <vue-tel-input-vuetify
          :disabledFetchingCountry="true"
          :preferredCountries="preferredCountry"
          :filterCountries="onlyCountries"
          validCharactersOnly
          :label="$t('billings.phone_3')"
          :inputOptions="{ showDialCode: true, tabindex: 0 }"
          :mode="'international'"
          v-model="company_client_phone_3"
          @onInput="
            $emit('update:comp_company_client_phone_3', company_client_phone_3)
          "
        ></vue-tel-input-vuetify>
      </v-col>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="mt-4"
            @click="showThirdRow = false"
          >
            <v-icon color="primary">mdi-minus</v-icon>
          </v-btn>
        </template>
        <span> {{ $t("clients.remove_contact_person") }} </span>
      </v-tooltip>
    </v-row>

    <!-- Company Bank PopUp Module -->
    <CompanyBankForm
      v-if="
        company_client_name &&
        company_client_name.company &&
        company_client_name.company.id
      "
      :bank="iban"
      :company_id="company_client_name.company.id"
      @updated="
        (newBanks) => {
          ibans = newBanks;
          iban = ibans[0];
        }
      "
      v-model="companyBankFormDialog"
    ></CompanyBankForm>
    <!-- Company Bank PopUp Module -->
    <AttentionMessage
      v-if="
        company_client_name &&
        typeof company_client_name == 'object' &&
        company_client_name.note
      "
      :note="company_client_name.note"
      v-model="attentionDialog"
    ></AttentionMessage>

    <!------------------ Address Component ----------------->
    <v-row
      class="group_label pa-2 pt-n2 mt-n2"
      v-if="
        (checkTag('client_billing_address') ||
          checkTag('client_shipping_address')) &&
        !isCompanyLeaser
      "
    >
      <!-- Main Address -->
      <v-col
        class="pb-2"
        cols="12"
        :sm="checkTag('client_billing_address') ? 6 : 12"
        v-if="
          checkTag('client_shipping_address') ? comp_is_shipping_visible : false
        "
      >
        <v-row>
          <!-- Main Address Title -->
          <v-col class="pb-2" cols="12" sm="12">
            <v-row>
              <span style="color: #2196f3">
                {{ $t("billings.main_address_title") }}
              </span>
            </v-row>
          </v-col>
          <!-- Main Address -->
          <v-row class="mt-1">
            <v-col cols="12" sm="12" class="ma-0 pa-0 pr-2">
              <AddressInput
                ref="shipping_address"
                :list="savedAddresses"
                v-model="shipping_address"
                :defaultAddressType="'main_address'"
                :label="$t('billings.main_address')"
                class="mt-n3"
              />
            </v-col>
          </v-row>
        </v-row>
      </v-col>

      <!-- Billing Address -->
      <v-col
        class="pb-2"
        cols="12"
        :sm="
          checkTag('client_shipping_address')
            ? !comp_is_shipping_visible
              ? 12
              : 6
            : 12
        "
        v-if="
          checkTag('client_billing_address') ? comp_is_billing_visible : false
        "
      >
        <v-row>
          <!-- Billing Address Title -->
          <v-col class="pb-2" cols="12" sm="12">
            <v-row>
              <span style="color: #2196f3">
                {{ $t("billings.billing_address_title") }}
              </span>
              <v-checkbox
                v-if="
                  comp_is_shipping_visible &&
                  checkTag('client_shipping_address')
                "
                class="ma-0 to-upper pa-0 pl-4"
                v-model="same_as"
                @change="$emit('update:comp_same_as', same_as)"
                :label="$t('billings.same_as_main_address')"
                hide-details
              ></v-checkbox>
            </v-row>
          </v-col>
          <!-- Billing Address -->
          <v-row class="mt-1" v-if="checkTag('client_billing_address')">
            <v-col cols="12" sm="12" class="ma-0 pa-0 pl-2">
              <AddressInput
                ref="billing_address"
                :list="savedAddresses"
                v-model="address"
                :disabled="same_as"
                class="mt-n3"
                :defaultAddressType="'billing_address'"
                :external-predictions="clientAddresses"
                :label="$t('billings.address')"
              />
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
    <edit-client-model
      v-if="openEditClientModel"
      @buttonClicked="updatedClient"
      :defaultClient="company_client_name"
      :showDialog.sync="openEditClientModel"
    ></edit-client-model>
  </v-card>
</template>
<style scoped>
.client_name {
  font-size: 14px;
  margin: 0px;
}

.client_detail {
  font-size: 11px;
  margin-bottom: 2px;
}
</style>
<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";

import EditClientModel from "@/components/EditClientModel.vue";
import { validationMixin } from "vuelidate";
import CompanyBankForm from "@/components/CompanySettings/CompanyBankForm.vue";
import {
  required,
  email,
  requiredIf,
  maxLength,
} from "vuelidate/lib/validators";
import VueTelInputVuetify from "@/components/PhoneNumberInput";

import { mapGetters } from "vuex";
import AddressInput from "@/components/AddressInput";
import TvaInput from "@/components/TvaInput.vue";
import AttentionMessage from "@/components/Client/AttentionMessage.vue";
import EmailInput from "@/components/EmailInput.vue";

export default {
  components: {
    "edit-client-model": EditClientModel,
    "vue-tel-input-vuetify": VueTelInputVuetify,
    AddressInput,
    TvaInput,
    EmailInput,
    CompanyBankForm,
    AttentionMessage,
  },
  mixins: [validationMixin],
  validations: {
    company_client_name: {
      required,
    },
    company_client_gsm: {
      maxLength: maxLength(45),
    },
    company_client_phone: {
      maxLength: maxLength(45),
    },
    company_client_gsm_2: {
      maxLength: maxLength(45),
    },
    company_client_phone_2: {
      maxLength: maxLength(45),
    },
    company_client_gsm_3: {
      maxLength: maxLength(45),
    },
    company_client_phone_3: {
      maxLength: maxLength(45),
    },
    familyClient: {
      required,
    },
    company_client_email: {
      email: (val) => email((val ?? "").toLowerCase()),
    },
    company_client_email_2: {
      email: (val) => email((val ?? "").toLowerCase()),
    },
    company_client_email_3: {
      email: (val) => email((val ?? "").toLowerCase()),
    },
    //   denomination: {
    //     required: requiredIf(function(model) {
    //       return this.entityType.id == 1;
    //     }),
    //   },
    tva: {
      required: requiredIf(function () {
        return this.entityType.id == 1;
      }),
    },
  },
  props: {
    comp_is_billing_visible: [Boolean],
    comp_company_client_first_name: [String, Object],
    comp_entityType: [String, Object],
    comp_client_id: [String, Number],
    comp_company: [String],
    comp_company_client_name: [String, Object],
    comp_search_client: [String, Number],
    comp_company_client_phone: [String, Number],

    comp_company_client_contact_name_2: [String, Number],
    comp_company_client_contact_name_3: [String, Number],

    comp_company_client_phone_2: [String, Number],
    comp_company_client_phone_3: [String, Number],
    comp_company_client_gsm: [String, Number],
    comp_company_client_gsm_2: [String, Number],
    comp_company_client_gsm_3: [String, Number],
    comp_company_client_fax: [String, Number],
    comp_company_client_email: [String, Number],
    comp_company_client_email_2: [String, Number],
    comp_company_client_email_3: [String, Number],
    comp_web: [String, Number],
    comp_currency: [String, Number],
    comp_representative_company_id: [String, Number],
    comp_user: [String, Number],
    comp_note: [String, Number],
    comp_familyClient: [String, Number, Object],
    comp_denomination: [String, Number, Object],
    comp_tva: [String, Number],
    comp_same_as: [Boolean, String, Number],

    comp_company_client_language: [String, Object, Number],

    comp_address: [String, Object, Number],
    comp_shipping_address: [String, Object, Number],
    comp_is_shipping_visible: [Boolean],
    comp_order_counts: [Object],
    comp_iban: [String, Object, Number],
    comp_taxation_reason_id: [String, Object, Number],
    search_only_companies: {
      type: Boolean,
      default: false,
    },
    showEntityType: {
      type: Boolean,
      default: true,
    },
    showLanguage: {
      type: Boolean,
      default: true,
    },
    showIban: {
      type: Boolean,
      default: false,
    },
    hideFamilyClientOnEdit: {
      type: Boolean,
      default: false,
    },
    hideEntityTypeOnEdit: {
      type: Boolean,
      default: false,
    },
    hideDenomination: {
      type: Boolean,
      default: true,
    },
    highlightTva: {
      type: Boolean,
      default: false,
    },
  },
  deactivated() {
    this.showSecondRow = false;
    this.showThirdRow = false;
    this.companyBankFormDialog = false;
    this.attentionDialog = false;
    this.clientAddresses = [];
    this.openEditClientModel = false;

    this.entityType = this.comp_entityType;
    this.client_id = this.comp_client_id;
    this.editBillingAddress = false;
    this.search_client = "";
    this.search_client_first_name = "";
    this.clients = [];
    this.clientsStatuses = [];
    this.client = null;
    this.company = null;
    this.company_client_contact_name_2 = null;
    this.company_client_contact_name_3 = null;
    this.company_client_phone = null;
    this.company_client_phone_2 = null;
    this.company_client_phone_3 = null;
    this.company_client_gsm = null;
    this.company_client_gsm_2 = null;
    this.company_client_gsm_3 = null;
    this.company_client_fax = null;
    this.company_client_email = null;
    this.company_client_email_2 = null;
    this.company_client_email_3 = null;
    this.web = null;
    this.currency = null;
    this.representative_company_id = null;
    this.user = null;
    this.note = null;
    this.tva = null;
    this.same_as = null;
    this.company_client_names = null;
    this.company_client_name = null;
    this.company_client_first_name = null;
    this.familyClient = null;
    this.language = null;

    this.addresses = [];
    this.address = null;

    this.shipping_addresses = [];
    this.shipping_address = null;

    this.denominations = [];
    this.denomination = null;
    this.isAutoSelect = false;
    (this.languages = []), (this.ibans = []);
    this.iban = null;
    this.taxation_reason_id = null;
    this.isIntializationSetupDone = false;
  },
  data() {
    return {
      showSecondRow: false,
      showThirdRow: false,
      companyBankFormDialog: false,
      attentionDialog: false,
      clientAddresses: [],
      openEditClientModel: false,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      autocompleteValueLocal: "off",
      defaultFamilyClient: {},
      entityType: this.comp_entityType,
      client_id: this.comp_client_id,
      editBillingAddress: false,
      search_client: "",
      search_client_first_name: "",
      clients: [],
      clientsStatuses: [],
      client: "",
      company: this.comp_company,
      company_client_contact_name_2: this.comp_company_client_contact_name_2,
      company_client_contact_name_3: this.comp_company_client_contact_name_3,
      company_client_phone: this.comp_company_client_phone,
      company_client_phone_2: this.comp_company_client_phone_2,
      company_client_phone_3: this.comp_company_client_phone_3,
      company_client_gsm: this.comp_company_client_gsm,
      company_client_gsm_2: this.comp_company_client_gsm_2,
      company_client_gsm_3: this.comp_company_client_gsm_3,
      company_client_fax: this.comp_company_client_fax,
      company_client_email: this.comp_company_client_email,
      company_client_email_2: this.comp_company_client_email_2,
      company_client_email_3: this.comp_company_client_email_3,
      web: this.comp_web,
      currency: this.comp_currency,
      representative_company_id: this.comp_representative_company_id,
      user: this.comp_user,
      note: this.comp_note,
      tva: this.comp_tva,
      same_as: false,
      company_client_names: [],
      company_client_name: this.comp_company_client_name,
      company_client_first_name: this.comp_company_client_first_name,
      familyClient: this.comp_familyClient,
      language: this.comp_company_client_language,

      addresses: [],
      address: this.comp_address,

      shipping_addresses: [],
      shipping_address: this.comp_shipping_address,

      token: this.$store.state.AccessToken,
      denominations: [],
      denomination: this.comp_denomination,
      isAutoSelect: false,
      languages: [],
      ibans: [],
      iban: this.comp_iban,
      taxation_reason_id: this.comp_taxation_reason_id,
      isIntializationSetupDone: false,
    };
  },
  async mounted() {
    this.initializeData();
  },
  activated() {
    this.initializeData();
  },
  computed: {
    isAnythingFilledForFirstRow() {
      if (
        this.language ||
        this.company_client_email ||
        (this.company_client_gsm &&
          (this.company_client_gsm ?? "").trim().length >= 5) ||
        (this.company_client_phone &&
          (this.company_client_phone ?? "").trim().length >= 5)
      ) {
        return true;
      }
      return false;
    },
    isAnythingFilledForThirdRow() {
      if (
        this.company_client_contact_name_2 ||
        this.company_client_email_2 ||
        (this.company_client_gsm_2 &&
          (this.company_client_gsm_2 ?? "").trim().length >= 5) ||
        (this.company_client_phone_2 &&
          (this.company_client_phone_2 ?? "").trim().length >= 5)
      ) {
        return true;
      }
      return false;
    },
    isAnythingFilledInTheThirdRow() {
      if (
        this.company_client_contact_name_3 ||
        this.company_client_email_3 ||
        (this.company_client_gsm_3 &&
          (this.company_client_gsm_3 ?? "").trim().length >= 5) ||
        (this.company_client_phone_3 &&
          (this.company_client_phone_3 ?? "").trim().length >= 5)
      ) {
        return true;
      }
      return false;
    },
    ...mapGetters(["defaultCompanyLanguage"]),
    clientId() {
      return this.comp_company_client_name?.id || null;
    },
    clientSearchErrorMessages() {
      if (this.company_client_name) {
        let clientStatus = this.clientsStatuses.find(
          (i) => i.id === this.company_client_name.status_id
        );
        if (clientStatus?.key === "client_blocked") {
          return this.$t("billings.text_messages.client_blocked");
        }
      }

      if (
        this.$store.state.validation &&
        !this.$v.company_client_name.required
      ) {
        return [
          this.$t("billings.text_messages.client_or_company_name_required"),
        ];
      }

      return [];
    },
    isCompanyLeaser() {
      return this.company_client_name
        ? !!this.company_client_name.is_leaser
        : false;
    },
    clientIsBlocked() {
      if (!this.company_client_name) {
        return false;
      }

      if (this.company_client_name.status_id == 10041003) {
        return true;
      }

      return false;
    },
    isCompanyEntitySelected() {
      if (this.entityType) {
        this.$emit("entitytypeChangedToCompany", this.entityType.id === 1);
        return this.entityType.id === 1;
      } else {
        this.$emit("entitytypeChangedToCompany", false);
        return false;
      }
    },
    isEditingAllowed() {
      // if(this.comp_order_counts){
      //     return !(this.comp_order_counts && this.comp_order_counts.bill == 0 );
      // }
      return this.$store.state.isEditingAllowed;
    },
    isEditingAllowedBeforeBill() {
      if (this.comp_order_counts) {
        return !(this.comp_order_counts && this.comp_order_counts.bill > 0);
      }
      return this.$store.state.isEditingAllowed;
    },
    savedAddresses() {
      let addresses = [];
      if (
        this.company_client_name &&
        typeof this.company_client_name == "object"
      ) {
        if (
          this.company_client_name.client_address &&
          this.company_client_name.client_address.length > 0
        ) {
          addresses = this.company_client_name.client_address;
        }
        if (
          this.company_client_name.company_address &&
          this.company_client_name.company_address.length > 0
        ) {
          addresses = this.company_client_name.company_address;
        }
      }
      return addresses;
    },
  },
  methods: {
    changedFamilyClient(){
        this.$emit('changedFamilyClient', this.familyClient);
    },
    async initializeData() {
      this.showSecondRow = false;
      this.showThirdRow = false;
      this.entityType = { id: 0 };
      this.header = { Authorization: "Bearer " + this.token };
      this.getCompanyDenominations();
      this.setDefaultFamilyClient();
      await this.getLanguages();
      this.clientsStatuses = await this.getCodes("client_statuses", false);
      this.language = this.defaultCompanyLanguage?.language_id;
      if (this.search_only_companies) {
        this.entityType = this.entityTypeSelectItems[1];
        this.$emit("update:comp_entityType", this.entityType);
      }
    },
    checkIfSecondRowShouldBeVisible() {
      if (this.isAnythingFilledForThirdRow) {
        this.showSecondRow = true;
      }
    },
    checkIfThirdRowShouldBeVisible() {
      if (this.isAnythingFilledInTheThirdRow) {
        this.showThirdRow = true;
      }
    },
    disableTaxationReason() {
      if (this.tva && this.userCountry) {
        return this.tva.toUpperCase().startsWith(this.userCountry.toUpperCase())
          ? true
          : false;
      }
      return false;
    },
    updatedSearchClient() {
      this.$store.commit("setSearchClientValue", this.search_client);
    },
    splitInParts(iban) {
      if (iban) {
        return iban.match(/.{1,4}/g).join(" "); // add space after every 4th character
      }
    },
    isSameCountryTva(event) {
      let tva = event?.target?.value ?? this.tva;
      this.$emit("entitytypeChangedToCompany", this.isCompanyEntitySelected);
      if (tva && tva.length > 0) {
        if (
          tva.substr(0, 2).toUpperCase() == localStorage.getItem("userCountry")
        ) {
          this.taxationReasons.forEach((element) => {
            if (element.key == "no_reason") {
              this.taxation_reason_id = element;
            }
          });
          this.selectDefaultTaxationReasonAsNoReason();
          return true;
        } else {
          this.taxationReasons.forEach((element) => {
            if (element.key == "intracommunity") {
              this.taxation_reason_id = element;
            }
          });
          return false;
        }
      }

      this.selectDefaultTaxationReasonAsNoReason();
      return true;
    },
    selectDefaultTaxationReasonAsNoReason() {
      this.taxationReasons.forEach((element) => {
        if (element.key == "no_reason") {
          this.taxation_reason_id = element;
        }
      });
    },
    saveClientAndAddBank() {
      this.$emit("saveClient", true);
      setTimeout(() => {
        this.addBank();
      }, 300);
    },
    editBank(bank) {
      if (this.confirmationDialog) return null;
      this.iban = bank;
      this.companyBankFormDialog = true;
    },
    addBank() {
      this.companyBankFormDialog = false;
      this.iban = null;
      setTimeout(() => (this.companyBankFormDialog = true), 10);
    },
    showAttentionMessage() {
      this.attentionDialog = true;
    },
    removeBank() {
      this.iban = null;
    },
    getIbans(company_id) {
      axios
        .get(API_BASE_URL + "/banks?company_id=" + company_id, {
          headers: this.header,
        })
        .then(({ data }) => {
          this.ibans = data.data;
          this.ibans.forEach((element) => {
            if (element.is_default) {
              this.iban = element;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //
        });
    },
    getClientAddresses(clientId) {
      return new Promise((resolve, reject) => {
        axios
          .get(API_BASE_URL + "/client_addresses/" + clientId, {
            headers: this.header,
          })
          .then((response) => {
            this.clientAddresses = response.data.data.map((a) => a.address);
            resolve(this.clientAddresses);
          })
          .catch(function (error) {
            reject(error);
          })
          .finally(() => {});
      });
    },
    updatedClient(data) {
      if (data) {
        let isCompany = !!data.representative_company_id;
        this.entityType = isCompany
          ? this.entityTypeSelectItems[1]
          : this.entityTypeSelectItems[0];
        // this.comp_company_client_name = data.name;
        this.company_client_name.name = data.name;
        this.search_client = data.name;
        this.name = data.name;
        this.company_client_first_name = data.first_name;
        this.currency = data.currency;
        this.company_client_email = data.email;
        this.familyClient = data.familyClient;
        this.fax = data.fax;
        this.company_client_phone = data.phone;
        if (data.company) {
          this.denominations.forEach((element) => {
            if (element.id == data.company.denomination_id) {
              this.denomination = element.id;
            }
          });
          this.tva = data.company.tva;
          this.$nextTick(() => {
            this.taxation_reason_id = data.company.taxation_reason;
          });
        }
        this.languages.forEach((element) => {
          if (data && data.language && element.id == data.language.id) {
            this.language = element;
          }
        });
        this.company_client_gsm = data.gsm;
        this.note = data.note;
        this.web = data.web;
        this.emitValues();
      }
    },
    editClient() {
      if (this.comp_company_client_name) {
        this.openEditClientModel = true;
        // this.$store.commit("seteditId",this.comp_company_client_name.id);
        // this.$router.push('/client/edit').catch(() => {});
      }
    },
    getLanguages() {
      return new Promise((resolve) => {
        this.getDataWithCache(
          "/languages",
          "languages",
          "languages",
          true
        ).then((data) => {
          resolve(data);
        });
      });
    },
    setDefaultFamilyClient() {
      this.familyClients.forEach((element) => {
        if (element.is_default == 1) {
          this.defaultFamilyClient = element;
          this.familyClient = this.defaultFamilyClient.id;
        }
      });
    },
    onResetPageClient() {
      this.client_id = null;
      this.search_client = null;
      this.search_client_first_name = null;
      this.client = null;
      this.company_client_name = null;
      this.company_client_names = [];
      this.taxation_reason_id = null;
      this.company_client_first_name = null;
      this.company_client_contact_name_2 = null;
      this.company_client_contact_name_3 = null;
      this.company_client_phone = null;
      this.company_client_phone_2 = null;
      this.company_client_phone_3 = null;
      this.company_client_gsm = null;
      this.company_client_gsm_2 = null;
      this.company_client_gsm_3 = null;
      this.language = null;
      this.company_client_fax = null;
      this.company_client_email = null;
      this.company_client_email_2 = null;
      this.company_client_email_3 = null;
      this.web = null;
      this.currency = null;
      this.representative_company_id = null;
      this.user = null;
      this.note = null;
      this.tva = null;
      this.familyClient = null;

      this.address = null;
      this.shipping_address = null;
      this.same_as = false;

      this.denomination = null;
      this.familyClient = this.defaultFamilyClient;

      this.updatedSearchClient();
      this.fixDenominationIfDenominationIsHidden();
      this.emitValues();
    },
    getCompanyDenominations() {
      this.getCodes("company_denomination", "company_denomination").then(
        (data) => {
          this.denominations = data;
          if (this.hideDenomination) {
            this.fixDenominationIfDenominationIsHidden();
          }
        }
      );
    },
    fetchClientsListDebounced() {
      if (!this.search_client || typeof this.search_client == "object") {
        return;
      }
      if (!this.isEditingAllowed) {
        // This means it is in edit mode
        axios
          .get(
            API_BASE_URL +
              "/clients/search?searchRegNumber=true" +
              (this.search_only_companies ? "&companiesOnly=true" : "") +
              (this.search_client
                ? "&search=" + encodeURIComponent(this.search_client)
                : ""),
            { headers: this.header }
          )
          .then(({ data }) => {
            data.data.forEach((element) => {
              element.clientname =
                element.name + " " + (element.first_name ?? "");
            });
            this.company_client_names = data.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      }
    },
    fetchClientsListDebouncedFromFirstName() {
      if (
        !this.search_client_first_name ||
        typeof this.search_client_first_name == "object"
      ) {
        return;
      }
      if (!this.isEditingAllowed) {
        // This means it is in edit mode
        axios
          .get(
            API_BASE_URL +
              "/clients/search?searchRegNumber=true" +
              (this.search_client_first_name
                ? "&search=" + encodeURIComponent(this.search_client_first_name)
                : ""),
            { headers: this.header }
          )
          .then(({ data }) => {
            data.data.forEach((element) => {
              element.clientname =
                element.name + " " + (element.first_name ?? "");
            });
            this.company_client_names = data.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            //
          });
      }
    },
    emitValues() {
      this.$emit("update:comp_entityType", this.entityType);
      this.$emit("update:comp_company_client_name", this.company_client_name);
      this.$emit(
        "update:comp_company_client_first_name",
        this.company_client_first_name
      );
      this.$emit("update:comp_search_client", this.search_client);
      this.$emit("update:comp_company_client_phone", this.company_client_phone);
      this.$emit(
        "update:comp_representative_company_id",
        this.representative_company_id
      );
      this.$emit("update:comp_company_client_gsm", this.company_client_gsm);
      this.$emit("update:comp_company_client_fax", this.company_client_fax);
      this.$emit("update:comp_company_client_email", this.company_client_email);

      this.$emit(
        "update:comp_company_client_contact_name_2",
        this.company_client_contact_name_2
      );
      this.$emit("update:comp_company_client_gsm_2", this.company_client_gsm_2);
      this.$emit(
        "update:comp_company_client_phone_2",
        this.company_client_phone_2
      );
      this.$emit(
        "update:comp_company_client_email_2",
        this.company_client_email_2
      );
      this.$emit(
        "update:comp_company_client_contact_name_3",
        this.company_client_contact_name_3
      );
      this.$emit("update:comp_company_client_gsm_3", this.company_client_gsm_3);
      this.$emit(
        "update:comp_company_client_phone_3",
        this.company_client_phone_3
      );
      this.$emit(
        "update:comp_company_client_email_3",
        this.company_client_email_3
      );

      this.$emit("update:comp_familyClient", this.familyClient);
      this.$emit("update:comp_denomination", this.denomination);
      this.$emit("update:comp_tva", this.tva);
      this.$emit("update:comp_same_as", this.same_as);
      this.$emit("update:comp_company_client_language", this.language);

      this.$emit("update:comp_address", this.address);

      this.$emit("update:comp_shipping_address", this.shipping_address);
      this.$emit("update:comp_iban", this.iban);
      this.$emit("update:comp_taxation_reason_id", this.taxation_reason_id);
    },
    sameAsMainAddress(val = true) {
      if (val) {
        this.address = this.shipping_address;
        this.editBillingAddress = true;
      }
      this.emitValues();
    },
    fixDenominationIfDenominationIsHidden() {
      if (
        this.isCompanyEntitySelected &&
        this.hideDenomination &&
        this.denominations &&
        this.denominations.length > 0 &&
        !this.denomination
      ) {
        this.denomination = this.denominations[0];
      }
    },
  },
  watch: {
    showSecondRow(val) {
      if (!val) {
        this.company_client_contact_name_2 = null;
        this.company_client_email_2 = null;
        this.company_client_gsm_2 = null;
        this.company_client_phone_2 = null;
        this.emitValues();
      }
    },
    showThirdRow(val) {
      if (!val) {
        this.company_client_contact_name_3 = null;
        this.company_client_email_3 = null;
        this.company_client_gsm_3 = null;
        this.company_client_phone_3 = null;
        this.emitValues();
      }
    },
    taxationReasons() {
      this.isSameCountryTva();
    },
    forceReloadComponentKey() {
      this.language = this.defaultCompanyLanguage?.language_id;
    },
    language(val) {
      this.$emit("update:comp_company_client_language", val);
    },
    familyClients() {
      this.setDefaultFamilyClient();
    },
    same_as: {
      handler: function (val) {
        if (!val) {
          this.address = null; // Billing Address set to null
          this.editBillingAddress = false;
        } else {
          this.sameAsMainAddress(); // Set Billing address same as Main Address
        }
        this.emitValues();
      },
    },
    address: {
      handler: function () {
        this.$nextTick(() => {
          this.emitValues();
        });
      },
    },
    shipping_address: {
      handler: function () {
        this.$nextTick(() => {
          this.emitValues();
        });
      },
    },
    iban: {
      handler: function () {
        this.$nextTick(() => {
          this.emitValues();
        });
      },
    },
    taxation_reason_id: {
      handler: function () {
        this.$nextTick(() => {
          this.emitValues();
        });
      },
    },
    comp_entityType: {
      handler: function () {
        this.entityType = { id: this.comp_entityType.id };
      },
    },
    comp_company_client_name: {
      handler: function () {
        this.company_client_name = this.comp_company_client_name;
        if (!this.company_client_name) {
          this.company_client_names = [];
          this.onResetPageClient();
        }
      },
    },
    comp_company_client_names: {
      handler: function () {
        this.company_client_names = this.comp_company_client_names;
      },
    },
    comp_company_client_first_name: {
      handler: function () {
        this.company_client_first_name = this.comp_company_client_first_name;
      },
    },
    comp_search_client: {
      handler: function () {
        this.search_client = this.comp_search_client;
      },
    },
    comp_company_client_phone: {
      handler: function () {
        this.company_client_phone = this.comp_company_client_phone;
      },
    },
    comp_representative_company_id: {
      handler: function () {
        this.representative_company_id = this.comp_representative_company_id;
      },
    },
    comp_company_client_gsm: {
      handler: function () {
        this.company_client_gsm = this.comp_company_client_gsm;
      },
    },
    comp_company_client_fax: {
      handler: function () {
        this.company_client_fax = this.comp_company_client_fax;
      },
    },
    comp_company_client_email: {
      handler: function () {
        this.company_client_email = this.comp_company_client_email;
      },
    },

    comp_company_client_contact_name_2: {
      handler: function () {
        this.company_client_contact_name_2 =
          this.comp_company_client_contact_name_2;
      },
    },
    comp_company_client_email_2: {
      handler: function () {
        this.company_client_email_2 = this.comp_company_client_email_2;
      },
    },
    comp_company_client_gsm_2: {
      handler: function () {
        this.company_client_gsm_2 = this.comp_company_client_gsm_2;
      },
    },
    comp_company_client_phone_2: {
      handler: function () {
        this.company_client_phone_2 = this.comp_company_client_phone_2;
      },
    },

    comp_company_client_contact_name_3: {
      handler: function () {
        this.company_client_contact_name_3 =
          this.comp_company_client_contact_name_3;
      },
    },
    comp_company_client_email_3: {
      handler: function () {
        this.company_client_email_3 = this.comp_company_client_email_3;
      },
    },
    comp_company_client_gsm_3: {
      handler: function () {
        this.company_client_gsm_3 = this.comp_company_client_gsm_3;
      },
    },
    comp_company_client_phone_3: {
      handler: function () {
        this.company_client_phone_3 = this.comp_company_client_phone_3;
      },
    },

    comp_familyClient: {
      handler: function () {
        this.familyClient = this.comp_familyClient;
      },
    },
    comp_denomination: {
      handler: function () {
        this.denomination = this.comp_denomination;
      },
    },
    comp_tva: {
      handler: function () {
        this.tva = this.comp_tva;
      },
    },
    tva: {
      handler: function (val) {
        this.comp_tva = val;
        this.$emit("update:comp_tva", val);
      },
    },
    // comp_company_client_language: {
    //   handler: function() {
    //     this.language = this.comp_company_client_language;
    //   },
    // },

    comp_address: {
      handler: function () {
        this.address = this.comp_address;
      },
    },

    comp_shipping_address: {
      handler: function () {
        this.shipping_address = this.comp_shipping_address;
      },
    },
    comp_iban: {
      handler: function () {
        this.iban = this.comp_iban;
      },
    },
    comp_same_as: {
      handler: function () {
        this.same_as = this.comp_same_as;
      },
    },
    familyClient(val) {
      if (!val) {
        this.familyClient = this.defaultFamilyClient.id;
      }
      this.$emit("update:comp_familyClient", this.familyClient);
    },

    company_client_first_name() {
      this.emitValues();
    },
    async company_client_name(val) {
      if (this.$refs["billing_address"]) {
        this.$refs["billing_address"].resetSplittedView();
      }
      if (this.$refs["shipping_address"]) {
        this.$refs["shipping_address"].resetSplittedView();
      }
      this.$v.company_client_name.$touch();
      if (val && typeof val == "object") {
        let isCompany = !!val.representative_company_id;
        this.entityType = isCompany
          ? this.entityTypeSelectItems[1]
          : this.entityTypeSelectItems[0];
        this.company_client_first_name = val.first_name;

        this.familyClient = val.family_client_id;
        this.company_client_email = val.email;
        this.company_client_gsm = val.gsm;

        // Second and third contact person
        this.company_client_contact_name_2 = val.contact_name_2;
        this.company_client_gsm_2 = val.gsm_2;
        this.company_client_phone_2 = val.phone_2;
        this.company_client_email_2 = val.email_2;

        this.company_client_contact_name_3 = val.contact_name_3;
        this.company_client_gsm_3 = val.gsm_3;
        this.company_client_phone_3 = val.phone_3;
        this.company_client_email_3 = val.email_3;

        this.checkIfSecondRowShouldBeVisible(val);
        this.checkIfThirdRowShouldBeVisible(val);

        this.languages.forEach((element) => {
          if (element.id == val.language_id) {
            this.language = element;
          }
        });
        this.company_client_phone = val.phone;
        this.representative_company_id = val.representative_company_id;

        if (
          !this.comp_address ||
          (this.comp_address &&
            typeof this.comp_address == "object" &&
            this.checkIfAddressEmpty(this.address))
        ) {
          // If Client is Private
          if (val.client_address && val.client_address.length > 0) {
            this.clientAddresses = await this.getClientAddresses(val.id);
            val.client_address.forEach((clientAddress) => {
              if (
                clientAddress.is_default &&
                clientAddress.status_id == 10071001 &&
                clientAddress.address_type_id == 10511003
              ) {
                // status_id = address_active && address_type = billing_address
                this.address = clientAddress.address;
              }
              if (
                clientAddress.is_default &&
                clientAddress.status_id == 10071001 &&
                clientAddress.address_type_id == 10511001
              ) {
                // status_id = address_active && address_type = main_address
                this.shipping_address = clientAddress.address;
              }
            });
            // If Client address is not selected as it should be.. then select any address as default for billing address
            if (this.checkIfAddressEmpty(this.address)) {
              val.client_address.forEach((clientAddress) => {
                if (
                  this.checkIfAddressEmpty(this.address) &&
                  clientAddress.status_id == 10071001
                ) {
                  // Only set the first one as default
                  this.address = clientAddress.address;
                }
              });
            }
            // If Client address is not selected as it should be.. then select any address as default for Main address
            if (this.checkIfAddressEmpty(this.shipping_address)) {
              val.client_address.forEach((clientAddress) => {
                if (
                  this.checkIfAddressEmpty(this.shipping_address) &&
                  clientAddress.status_id == 10071001
                ) {
                  // Only set the first one as default
                  this.shipping_address = clientAddress.address;
                }
              });
            }
          }
          // If Client is a company
          if (val.company_address && val.company_address.length > 0) {
            val.company_address.forEach((companyAddress) => {
              if (
                companyAddress.is_default &&
                companyAddress.status_id == 10071001 &&
                companyAddress.address_type_id == 10511003
              ) {
                // status_id = address_active && address_type = billing_address
                this.address = companyAddress.address;
              }
              if (
                companyAddress.is_default &&
                companyAddress.status_id == 10071001 &&
                companyAddress.address_type_id == 10511001
              ) {
                // status_id = address_active && address_type = main_address
                this.shipping_address = companyAddress.address;
              }
            });
            // If company address is not selected as it should be.. then select any address as default for billing address
            if (this.checkIfAddressEmpty(this.address)) {
              val.company_address.forEach((companyAddress) => {
                if (
                  this.checkIfAddressEmpty(this.address) &&
                  companyAddress.status_id == 10071001
                ) {
                  // Only set the first one as default
                  this.address = companyAddress.address;
                }
              });
            }
            // If company address is not selected as it should be.. then select any address as default for Main address
            if (this.checkIfAddressEmpty(this.shipping_address)) {
              val.company_address.forEach((companyAddress) => {
                if (
                  this.checkIfAddressEmpty(this.shipping_address) &&
                  companyAddress.status_id == 10071001
                ) {
                  // Only set the first one as default
                  this.shipping_address = companyAddress.address;
                }
              });
            }
          }
        }

        if (val.company) {
          this.denomination = val.company.denomination_id;
          this.tva = val.company.tva;
          this.getIbans(val.company.id);
          this.$nextTick(() => {
            this.taxation_reason_id = val.company.taxation_reason;
          });
        }
        this.emitValues();
      } else {
        this.fixDenominationIfDenominationIsHidden();
        this.checkIfSecondRowShouldBeVisible();
        this.checkIfThirdRowShouldBeVisible();
        if (!this.language) {
          this.language = this.defaultCompanyLanguage?.language_id;
        }
        this.emitValues();
      }
      if (!val) {
        this.showSecondRow = false;
        this.showThirdRow = false;
      }
    },
    search_client(val) {
      if (!val || this.client_id == val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchClientsListDebounced();
      }, 1500);
    },
    search_client_first_name(val) {
      if (!val || this.client_id == val) {
        return;
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchClientsListDebouncedFromFirstName();
      }, 500);
    },
  },
};
</script>
<style>
.iban_name {
  font-size: 14px;
}

.iban_detail {
  font-size: 10px;
  margin: 0px !important;
  white-space: nowrap;
  overflow: hidden;
}
</style>
