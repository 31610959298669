<template>
  <v-col cols="12">
    <v-row>
      <v-col v-if="searchWithLocationName && !splittedView" cols="12" :sm="4">
        <v-combobox
          autocomplete="off"
          role="presentation"
          :value="value"
          v-model="locationNameBySearchInput"
          :items="locationNames"
          :required="required"
          :disabled="disabled"
          class="to-upper locationName"
          append-icon="mdi-close"
          v-on:keydown="handleInput($event, 'alphanumeric', 50)"
          :error-messages="errorMessages"
          :search-input.sync="searchLocationName"
          no-filter
          :single-line="true"
          @input.native="
            locationNameBySearchInput = null;
            locationNames = [];
          "
          @click:append="
            selectedAddress = null;
            predictions = [];
            location_name = null;
            locationNameBySearchInput = null;
          "
          hide-details="true"
        >
          <template #label>
            {{ $t("addresses.location_name") }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <v-list-item-content style="color: black !important">
              <span>
                <strong class="location_name">{{ item.location_name }}</strong>
              </span>
              <br />
              <span class="location_address">{{ computeLabel(item) }}</span>
            </v-list-item-content>
          </template>
          <template slot="selection" slot-scope="{ item }">
            <p class="address_detail mb-0" style="overflow: hidden">
              <template
                v-if="item && typeof item == 'object' && item.location_name"
              >
                {{ item.location_name.toUpperCase() }}
              </template>
              <template v-if="item && typeof item == 'string'">
                {{ item.toUpperCase() }}
              </template>
            </p>
          </template>
        </v-combobox>
      </v-col>
      <template v-if="detailedViewAllowed">
        <!-- Location Name -->
        <v-col
          v-if="splittedView && showLocationName"
          cols="12"
          :sm="splittedView ? 2 : 0"
        >
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="selectedAddress.location_name"
            class="to-upper"
            :label="$t('addresses.location_name')"
            v-on:keydown.enter.prevent=""
            v-on:keydown="handleInput($event, 'alphanumeric', 80)"
            :outlined="is_boxes"
            :dense="is_boxes"
            :prepend-icon="splittedView ? 'mdi-rectangle-outline' : ''"
            @click:prepend="splittedView = !splittedView"
            @input="resetIdOfAddressToForceUpdateInBackend"
            hide-details="true"
          ></v-text-field>
        </v-col>
        <!-- House Number -->
        <v-col
          v-if="splittedView"
          cols="12"
          sm="12"
          :md="splittedView ? (showLocationName ? 1 : 2) : 0"
        >
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="selectedAddress.building_id"
            class="to-upper"
            :label="$t('addresses.house_number')"
            v-on:keydown.enter.prevent=""
            v-on:keydown="handleInput($event, 'alphanumeric', 80)"
            :outlined="is_boxes"
            :dense="is_boxes"
            @input="resetIdOfAddressToForceUpdateInBackend"
            :prepend-icon="
              splittedView && !showLocationName ? 'mdi-rectangle-outline' : ''
            "
            @click:prepend="splittedView = !splittedView"
            hide-details="true"
          ></v-text-field>
        </v-col>
        <!-- Street Full View Width -->
        <v-col
          v-if="!splittedView"
          cols="12"
          :sm="
            splittedView
              ? 5
              : showAddressType
              ? in_two_lines
                ? 12
                : 8
              : searchWithLocationName
              ? 8
              : 12
          "
        >
          <v-combobox
            autocomplete="off"
            role="presentation"
            :value="value"
            v-model="selectedAddress"
            :items="
              predictions && predictions.length > 0
                ? predictions
                : clientAddressessFromList
            "
            :required="required"
            :disabled="disabled"
            class="to-upper"
            append-icon="mdi-close"
            v-on:keydown="handleInput($event, 'alphanumeric', 50)"
            :error-messages="errorMessages"
            :search-input.sync="search"
            no-filter
            :single-line="singleLine"
            @input.native="
              selectedAddress = null;
              predictions = [];
              searchWithLocationName ? '' : (location_name = null);
            "
            @focusout.native="resetSelectedAddress()"
            @blur.native="resetSelectedAddress()"
            @change="resetSelectedAddress()"
            @click:append="
              selectedAddress = null;
              predictions = [];
              location_name = null;
            "
            :prepend-icon="!splittedView ? 'mdi-grid' : ''"
            @click:prepend="splittedView = !splittedView"
            return-object
            @input="resetIdOfAddressToForceUpdateInBackend"
            hide-details="true"
          >
            <template #label>
              <span v-if="required" class="red--text"><strong>* </strong></span>
              {{ $t(label) || $t("tyre_hotel.vehicle.address") }}
            </template>
            <template slot="selection" slot-scope="{ item }">
              <p class="address_detail mb-0" style="overflow: hidden">
                {{ computeLabel(item) }}
              </p>
            </template>

            <template slot="item" slot-scope="{ item }">
              <v-list-item-content style="color: black !important">
                <p>
                  {{ computeLabel(item) }}
                  <!-- <template v-if="item.address_type_id">
                                {{ getAddressTypeFromId(item.address_type_id) }}
                            </template> -->
                </p>
              </v-list-item-content>
            </template>
            <template slot="append">
              <div style="background-color: white; position: relative">
                <v-icon
                  v-if="search || selectedAddress"
                  :size="18"
                  @click="copyAddress()"
                  >mdi-content-copy</v-icon
                >
                <v-icon
                  v-if="search || selectedAddress"
                  @click="
                    selectedAddress = null;
                    search = null;
                  "
                  >mdi-close</v-icon
                >
              </div>
            </template>
            <!-- <template slot="prepend-item">
                    <div @click="savedAddress" v-for="(savedAddress, index) in list" :key="index" tabindex="0" aria-selected="false" id="list-item-451-4" role="option" class="v-list-item v-list-item--link theme--light">
                        <v-list-item-content v-if="savedAddress.address">
                            {{ computeLabel(savedAddress.address) }}
                        </v-list-item-content>
                    </div>
                    
                </template> -->
          </v-combobox>
        </v-col>

        <!-- Street Splitted View -->
        <v-col v-if="splittedView">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="selectedAddress.street"
            class="to-upper"
            :label="$t('tyre_hotel.vehicle.address')"
            v-on:keydown.enter.prevent=""
            v-on:keydown="handleInput($event, 'alphanumeric', 80)"
            :outlined="is_boxes"
            :dense="is_boxes"
            @input="resetIdOfAddressToForceUpdateInBackend"
            hide-details="true"
          ></v-text-field>
        </v-col>
        <!-- Zip -->
        <v-col v-if="splittedView" cols="12" :sm="splittedView ? 2 : 0">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="selectedAddress.zip"
            class="to-upper"
            :label="$t('addresses.zipcode')"
            v-on:keydown.enter.prevent=""
            v-on:keydown="handleInput($event, 'alphanumeric', 80)"
            :outlined="is_boxes"
            :dense="is_boxes"
            @input="resetIdOfAddressToForceUpdateInBackend"
            :hide-details="selectedAddress && selectedAddress.zip && selectedAddress.zip.length >= zipLength ? false : true"
            :error-messages="
                selectedAddress && selectedAddress.zip && selectedAddress.zip.length >= zipLength
                ? [this.$t('billings.max_zip_length', {
                                    char: zipLength -1,
                                    })]
                : []
            "
          ></v-text-field>
        </v-col>
        <!-- City -->
        <v-col v-if="splittedView" cols="12" :sm="splittedView ? 2 : 0">
          <v-text-field
            :autocomplete="autocompleteValueLocal"
            v-model="selectedAddress.city"
            class="to-upper"
            :label="$t('addresses.city')"
            v-on:keydown.enter.prevent=""
            v-on:keydown="handleInput($event, 'alphanumeric', 80)"
            :outlined="is_boxes"
            :dense="is_boxes"
            @input="resetIdOfAddressToForceUpdateInBackend"
            hide-details="true"
          ></v-text-field>
        </v-col>
        <!-- Country -->
        <v-col v-if="splittedView" cols="12" :sm="splittedView ? 1 : 0">
          <v-autocomplete
            role="presentation"
            autocomplete="off"
            class="to-upper mr-3"
            flat
            :items="countries"
            item-text="key"
            item-value="id"
            v-model="selectedAddress.country_code"
            :label="$t('addresses.country')"
            :required="required"
            :disabled="disabled"
            @change="resetIdOfAddressToForceUpdateInBackend"
            hide-details="true"
            :append-icon="''"
          ></v-autocomplete>
        </v-col>
        <!-- Address Type -->
        <v-col
          v-if="showAddressType"
          cols="12"
          :sm="showAddressType ? (in_two_lines ? 10 : 4) : 0"
        >
          <v-combobox
            class="to-upper"
            :autocomplete="autocompleteValueLocal"
            v-model="addressType"
            :items="cachedAdressTypes"
            item-value="id"
            item-text="text"
            :label="$t('address_types.title')"
            v-on:keydown.enter.prevent=""
            :outlined="is_boxes"
            :dense="is_boxes"
            :hide-details="hideDetails"
          ></v-combobox>
        </v-col>
      </template>
      <template v-else>
        <!-- Street Full View Width -->
        <v-col cols="12" :sm="searchWithLocationName ? 8 : 12">
          <v-combobox
            autocomplete="off"
            role="presentation"
            :value="value"
            v-model="selectedAddress"
            :items="predictions"
            :required="required"
            :disabled="disabled"
            class="to-upper"
            append-icon="mdi-close"
            v-on:keydown="handleInput($event, 'alphanumeric', 50)"
            :error-messages="errorMessages"
            :search-input.sync="search"
            no-filter
            :single-line="singleLine"
            @input.native="
              selectedAddress = null;
              predictions = [];
            "
            @click:append="
              selectedAddress = null;
              predictions = [];
              location_name = null;
            "
            return-object
            @input="resetIdOfAddressToForceUpdateInBackend"
            hide-details="true"
          >
            <template #label>
              <span v-if="required" class="red--text"><strong>* </strong></span>
              {{ $t(label) || $t("tyre_hotel.vehicle.address") }}
            </template>
            <template slot="selection" slot-scope="{ item }">
              <p class="address_detail mb-0" style="overflow: hidden">
                {{ computeLabel(item) }}
              </p>
            </template>

            <template slot="item" slot-scope="{ item }">
              <v-list-item-content>
                <p>
                  {{ computeLabel(item) }}
                </p>
              </v-list-item-content>
            </template>
            <template slot="append">
              <div style="background-color: white; position: relative">
                <v-icon
                  v-if="search || selectedAddress"
                  :size="18"
                  @click="copyAddress()"
                  >mdi-content-copy</v-icon
                >
                <v-icon
                  v-if="search || selectedAddress"
                  @click="
                    selectedAddress = null;
                    search = null;
                  "
                  >mdi-close</v-icon
                >
              </div>
            </template>
          </v-combobox>
        </v-col>
      </template>
    </v-row>
  </v-col>
</template>
<script>
/*global google*/
import _axios from "@/plugins/axios";
import _external_call_axios from "@/plugins/external_call_axios";
import { API_BASE_URL } from "@/config";
import debounce from "lodash/debounce";
import _ from "lodash";

export default {
  name: "AddressInput",
  emits: ["input"],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      default: "tyre_hotel.vehicle.address",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAddressType: {
      type: Boolean,
      default: false,
    },
    defaultAddressType: {
      type: String,
      default: "main_address",
    },
    currentAddressType: {
      type: Object,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: null,
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
    externalPredictions: {
      type: Array,
      default: () => [],
    },
    showLocationName: {
      type: Boolean,
      default: false,
    },
    searchWithLocationName: {
      type: Boolean,
      default: false,
    },
    detailedViewAllowed: {
      type: Boolean,
      default: true,
    },
    in_two_lines: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
        zipLength: 19,
      locationNameBySearchInput: null,
      locationNames: [],
      searchLocationName: null,
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      selectedCountry: null,
      selectedAddress: this.value,
      predictions: [],
      mapAPIKey: null,
      mapAPIProvider: null,
      defaultCountry: null,
      search: null,
      autocomplete: null,
      splittedView: false,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      mapProviders: {
        gmap: "GMAP",
        openstreetmap: "OPENSTREETMAP",
      },
      addressType: null,
      autocompleteValueLocal: "off",
      location_name: null,
    };
  },
  created() {
    let companyProperties = JSON.parse(
      localStorage.getItem("company_properties")
    );

    let companyAPIProvider = companyProperties.find(
      (property) => property.company_setting_key == "ADDRESS_LOCALISATION_API"
    );

    this.mapAPIProvider =
      companyAPIProvider !== undefined
        ? companyAPIProvider.value
        : this.mapProviders.openstreetmap;
    companyProperties.find(
      (property) => property.company_setting_key == "ADDRESS_LOCALISATION_KEY"
    );

    if (this.mapAPIProvider == this.mapProviders.gmap) {
      try {
        this.mapAPIKey = companyProperties.find(
          (property) =>
            property.company_setting_key == "ADDRESS_LOCALISATION_KEY"
        ).value;
      } catch (error) {
        this.$toast.error(this.$t("rentals.localisation_key_error"));
      }

      const src = `https://maps.googleapis.com/maps/api/js?key=${this.mapAPIKey}&libraries=places&callback=Function.prototype`;

      if (
        Array.from(document.querySelectorAll("script")).find(
          (item) => item.src == src
        ) === undefined
      ) {
        this.loadScriptAsync(src);
      }

      const placeDetailSrc = `https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&fields=name%2CaddressComponent&key=${this.mapAPIKey}`;

      if (
        Array.from(document.querySelectorAll("script")).find(
          (item) => item.src == src
        ) === undefined
      ) {
        this.loadScriptAsync(placeDetailSrc);
      }
    }

    // Enable Debounce
    this.debounceHandler = debounce(() => {
      this.getPredictions();
    }, 1000);

    // Load Default Value
    if (this.value) {
      this.selectedAddress = this.value;
      if (this.value && this.value.location_name) {
        this.location_name = this.value.location_name;
      }
    }

    this.setDefaultAddressType();
  },
  beforeUnmount() {
    this.debounceHandler.cancel();
  },
  mounted() {
    this.selectDefaultCountry();
  },
  watch: {
    locationNameBySearchInput(val) {
      if (val && typeof val == "object") {
        this.selectedAddress = val;
      }
      if (val && typeof val == "string") {
        this.location_name = val.toUpperCase();
        if (this.selectedAddress) {
          this.selectedAddress.location_name = val.toUpperCase();
        }
      }
    },
    searchLocationName: _.debounce(function () {
      this.getLocationsFromBackend();
    }, 1000),
    splittedView(val) {
      if (val) {
        if (
          this.selectedAddress &&
          typeof this.selectedAddress.city == "object"
        ) {
          this.selectedAddress.country_code =
            this.selectedAddress.city?.country_id ||
            this.selectedAddress?.country_code;
          this.selectedAddress.city = this.selectedAddress.city?.name;
        } else if (!this.selectedAddress) {
          this.selectedAddress = {};
          this.selectedAddress.street = null;
          this.selectedAddress.building_id = null;
          this.selectedAddress.place_id = null;
          this.selectedAddress.zip = null;
          this.selectedAddress.country = null;
          this.selectedAddress.country_code = null;
          this.selectedAddress.country_id = null;
          this.selectedAddress.county = null;
          this.selectedAddress.state = null;
          this.selectedAddress.region = null;
          this.selectedAddress.city = null;
          this.selectedAddress.lat = null;
          this.selectedAddress.long = null;
          this.selectedAddress.provider = null;
          this.selectedAddress.formatted = null;
          this.selectedAddress.name = null;
          this.selectedAddress.location_name = null;
        }
      }
    },
    countries() {
      this.selectDefaultCountry();
    },
    externalPredictions: {
      handler: function (val) {
        if (!this.isset(val)) {
          return null;
        }
        this.predictions = val;
      },
      immediate: true,
    },
    currentAddressType(val) {
      this.cachedAdressTypes.forEach((element) => {
        if (element.id == val.id) {
          this.addressType = element;
        }
      });
    },
    addressType(val) {
      this.currentAddressType = val;
      this.$emit("update:currentAddressType", val);
    },
    cachedAdressTypes(val) {
      if (val && val.length) {
        this.setDefaultAddressType();
      }
    },
    search(newValue) {
      if (typeof newValue === "string") {
        this.debounceHandler(newValue);
      }
    },
    value(newVal) {
      this.selectedAddress = newVal;
      if (
        newVal &&
        typeof newVal == "object" &&
        newVal.location_name &&
        this.searchWithLocationName
      ) {
        this.location_name = newVal.location_name;
        this.locationNames = [newVal];
        this.locationNameBySearchInput = newVal.location_name;
      }
    },
    location_name(newValue) {
      if (this.selectedAddress && typeof this.selectedAddress == "object") {
        this.selectedAddress["location_name"] = newValue;
      }
    },
    selectedAddress(newValue) {
      if (newValue) {
        if (
          typeof newValue == "object" &&
          newValue.building_id == null &&
          newValue.city == null &&
          newValue.country == null &&
          newValue.county == null &&
          newValue.formatted == null &&
          newValue.zip == null
        ) {
          this.predictions = [];
        } else {
          this.predictions = [newValue];
        }
        this.clientAddressessFromList.forEach((element) => {
          if (
            element.human_readable_address == newValue.human_readable_address
          ) {
            return;
          } else {
            this.predictions.push(element);
          }
        });
      }
      if (
        newValue &&
        typeof newValue == "string" &&
        newValue.includes("AUTOSELECT_ADDRESS_FIRST")
      ) {
        let addressPassedForAutoSelect = newValue
          .replace("AUTOSELECT_ADDRESS_FIRST", "")
          .replace("\n", "");
        this.search = addressPassedForAutoSelect;
        this.getPredictions(true);
      }
      if (newValue && typeof newValue != "object") {
        // reset the selected address and predications on blur event
        this.selectedAddress = null;
        this.predictions = [];
      }
      if (this.location_name) {
        if (this.selectedAddress && typeof this.selectedAddress == "object") {
          this.selectedAddress["location_name"] = this.location_name;
        }
        newValue["location_name"] = this.location_name;
      }
      // Change to Uppercase to prevent duplicate country id in formation
      if (newValue && newValue.country_code) {
        newValue.country_code = newValue.country_code.toUpperCase();
      }
      if (newValue && newValue.location_name) {
        this.location_name = newValue.location_name.toUpperCase();
      }

      // Update Input Address
      this.updateAddressModel(newValue);

      if (
        this.mapAPIProvider === this.mapProviders.gmap &&
        typeof newValue != "string" &&
        newValue?.place_id !== undefined &&
        newValue?.building_id === undefined
      ) {
        this.getGooglePlaceDetails(newValue);
      }

      // Set Country Field in the Input if In Address thier is any other country id -- After selecting manually
      if (newValue && newValue.country_code) {
        if (this.countries && this.countries.length > 0) {
          this.defaultCountry = this.countries.find(
            (country) =>
              country.key.toUpperCase() == newValue.country_code.toUpperCase()
          );
          this.updateSelectedCountry();
        }
      }

      // Set Country Field in the Input if In Address thier is any other country id -- After setting the db value
      if (newValue && newValue.city && newValue.city.country_id) {
        if (this.countries && this.countries.length > 0) {
          this.defaultCountry = this.countries.find(
            (country) =>
              country.key.toUpperCase() ==
              newValue.city.country_id.toUpperCase()
          );
          this.updateSelectedCountry();
        }
      }
      this.updatedCityAndCountrySplittedView();
    },
  },
  computed: {
    countries() {
      return this.$store.state.countries;
    },
    clientAddressessFromList() {
      let allClientAddress = [];
      this.list.forEach((element) => {
        // element.address.address_type_id = element.address_type_id;
        // Check if already added
        let alreadyAdded = false;
        allClientAddress.forEach((alreadyAddedAddress) => {
          if (alreadyAddedAddress == element.address) {
            alreadyAdded = true;
          }
        });
        if (!alreadyAdded) {
          if (this.defaultAddressType == "main_address") {
            if (allClientAddress.length == 0) {
              allClientAddress.push(element.address);
            }
          } else {
            allClientAddress.push(element.address);
          }
        }
      });
      return allClientAddress;
    },
  },
  activated() {
    this.predictions = [];
  },
  methods: {
    resetSelectedAddress() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.selectedAddress && typeof this.selectedAddress == "string") {
            this.selectedAddress = null;
          }
        }, 50);
      });
    },
    getAddressTypeFromId() {
      return;
    },
    resetSplittedView() {
      this.splittedView = false;
    },
    resetIdOfAddressToForceUpdateInBackend() {
      if (this.selectedAddress && typeof this.selectedAddress == "object") {
        this.selectedAddress.id = null;
        this.selectedAddress.provider = this.mapAPIProvider;
        this.selectedAddress.place_id = this.mapAPIKey;
      }
      if(this.selectedAddress && this.selectedAddress.zip){
        this.selectedAddress.zip = this.selectedAddress.zip.substr(0,this.zipLength);
      }
    },
    updatedCityAndCountrySplittedView() {
      this.splittedView = !this.splittedView;
      this.$nextTick(() => {
        this.splittedView = !this.splittedView;
      });
    },
    copyAddress() {
      if (
        this.selectedAddress &&
        typeof this.selectedAddress == "object" &&
        this.selectedAddress.human_readable_address
      ) {
        this.copyText(this.selectedAddress.human_readable_address);
      } else if (
        this.selectedAddress &&
        typeof this.selectedAddress == "object" &&
        this.selectedAddress.formatted
      ) {
        this.copyText(this.selectedAddress.formatted);
      }
    },
    getAddressInputWidth() {
      if (this.showAddressType && this.splittedView) {
        return 5;
      } else if (!this.showAddressType && !this.splittedView) {
        return 12;
      } else {
        return 7;
      }
    },
    selectDefaultCountry() {
      if (this.countries && this.countries.length > 0) {
        this.defaultCountry = this.countries.find(
          (country) => country.is_default == 1
        );
        this.updateSelectedCountry();
      }
    },
    setDefaultAddressType() {
      if (
        this.defaultAddressType &&
        this.cachedAdressTypes &&
        this.cachedAdressTypes.length > 0
      ) {
        this.cachedAdressTypes.forEach((element) => {
          if (element.key == this.defaultAddressType) {
            this.addressType = element;
          }
        });
      }
    },
    loadScriptAsync(src) {
      const script = window.document.createElement("script");
      script.src = src;
      script.async = true;
      script.defer = true;

      const promise = new Promise((resolve, reject) => {
        script.addEventListener(
          "load",
          (event) => {
            resolve(event);
          },
          false
        );

        script.addEventListener("error", (error) => reject(error));
      });

      window.document.body.appendChild(script);
      return promise;
    },

    googlePlacePredictions(AUTOSELECT_ADDRESS_FIRST) {
      let autocomplete = new google.maps.places.AutocompleteService();

      autocomplete.getPlacePredictions(
        {
          input: this.search,
          componentRestrictions: {
            country: this.selectedCountry || this.defaultCountry.id,
          },
        },
        (predictions, status) => {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            this.predictions = this.normalizeGmapResponse(predictions);
            if (
              AUTOSELECT_ADDRESS_FIRST &&
              this.predictions &&
              this.predictions.length > 0
            ) {
              this.selectedAddress = this.predictions[0];
            }
          }
        }
      );
    },
    updateSelectedCountry() {
      if (this.selectedAddress) {
        this.selectedCountry =
          this.selectedAddress?.city?.country_id ||
          this.selectedAddress?.country_code ||
          this.defaultCountry.id;
        if (
          this.countries.find(
            (country) => country.id == this.selectedCountry
          ) == undefined
        ) {
          this.countries.push({
            id: this.selectedCountry,
            is_default: 0,
            key: this.selectedCountry,
          });
        }
      } else {
        this.selectedCountry = this.defaultCountry.id.toUpperCase();
      }
    },
    getLocationsFromBackend() {
      _axios
        .get(
          API_BASE_URL +
            "/addresses?items_per_page=12&location_name=" +
            this.searchLocationName,
          {
            headers: this.header,
          }
        )
        .then(({ data }) => {
          this.locationNames = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPredictions(AUTOSELECT_ADDRESS_FIRST) {
      if (!this.selectedCountry) {
        return this.$toast.error("Please Select Country");
      }
      switch (this.mapAPIProvider) {
        case this.mapProviders.gmap:
          this.googlePlacePredictions(AUTOSELECT_ADDRESS_FIRST);
          break;
        default:
          this.openStreetMapPredictions(AUTOSELECT_ADDRESS_FIRST);
      }
    },
    getGooglePlaceDetails(placeInfo) {
      let map = new google.maps.Map(document.createElement("div"));
      let placeService = new google.maps.places.PlacesService(map);

      placeService.getDetails(
        {
          placeId: placeInfo.place_id,
          fields: ["address_components", "geometry"],
        },
        (place, status) => {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            let normalizedResponse = this.normalizeGmapPlaceDetail(
              place,
              placeInfo
            );
            this.updateAddressModel(normalizedResponse);
          }
        }
      );
    },
    updateAddressModel(value) {
      this.$emit("input", value);
    },

    async openStreetMapPredictions(AUTOSELECT_ADDRESS_FIRST) {
      if (!this.search || typeof this.search == "object") {
        return;
      }

      await _external_call_axios
        .get(
          "https://api.geoapify.com/v1/geocode/search?apiKey=" +
            this.mapApiKey +
            "&text=" +
            this.search.toString() +
            // ( this.searchWithoutCountry ? '' : "&country=" + this.selectedCountry ) +
            "&format=json"
        )
        .finally(() => {})
        .then((data) => {
          this.predictions =
            data && data.data && data.data.results
              ? this.normalizeOpenStreetMapResponse(data.data.results)
              : [];
          if (
            AUTOSELECT_ADDRESS_FIRST &&
            this.predictions &&
            this.predictions.length > 0
          ) {
            this.selectedAddress = this.predictions[0];
          }
        });
    },
    normalizeOpenStreetMapResponse(apiResponse) {
      return apiResponse.map((response) => {
        return {
          street: this.removeLettersFromWordsMixedWithNumber(
            response?.street || ""
          ),
          building_id: response?.housenumber || null,
          place_id: response.place_id,
          zip: response?.postcode || null,
          country: response?.country || null,
          county: response?.county || null,
          state: response?.state || null,
          region: response?.region || null,
          country_code: response?.country_code || null,
          city: response?.city || null,
          lat: response?.lat || null,
          long: response?.lon || null,
          provider: this.mapAPIProvider,
          formatted: response?.formatted || null,
          name: response?.name || null,
          location_name: response?.location_name || null,
        };
      });
    },
    normalizeGmapResponse(apiResponse) {
      return apiResponse.map((response) => {
        return {
          street: response?.description,
          label: response?.description,
          name: response?.name || null,
          place_id: response.place_id,
          location_name: response?.location_name || null,
        };
      });
    },
    normalizeGmapPlaceDetail(place, placeInfo) {
      let addressComponent = place.address_components;
      let location = place.geometry.location;

      let ShouldBeComponent = {
        building_id: ["street_number"],
        zip: ["postal_code"],
        street: ["street_address", "route"],
        state: ["state"],
        county: [
          "administrative_area_level_1",
          "administrative_area_level_2",
          "administrative_area_level_3",
          "administrative_area_level_4",
          "administrative_area_level_5",
        ],
        city: [
          "locality",
          "sublocality",
          "sublocality_level_1",
          "sublocality_level_2",
          "sublocality_level_3",
          "sublocality_level_4",
        ],
        country: ["country"],
      };

      let address = {
        building_id: "",
        zip: "",
        street: "",
        county: "",
        state: "",
        city: "",
        country: "",
        place_id: placeInfo.place_id,
        lat: location.lat(),
        long: location.lng(),
        provider: this.mapAPIProvider,
        location_name: null,
      };
      addressComponent.forEach((component) => {
        for (let shouldBe in ShouldBeComponent) {
          if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
            if (shouldBe === "country") {
              address[shouldBe] = component.short_name;
              address.country_code = component.short_name;
            } else {
              address[shouldBe] = component.long_name;
            }
          }
        }
      });

      address.label = this.computeLabel(address);
      address.street = this.removeLettersFromWordsMixedWithNumber(
        address.street
      );

      return address;
    },
    computeLabel(address) {
      if (this.isset(address.formatted)) {
        return address.formatted;
      }

      let label = "";

      if (this.isset(address.building_id)) {
        label += address.building_id;
      }

      if (this.isset(address.street)) {
        if (label != "") {
          label += ", ";
        }
        label += address.street;
      }

      if (this.isset(address.zip)) {
        if (label != "") {
          label += ", ";
        }
        label += address.zip;
      }

      if (this.isset(address.county)) {
        if (label != "") {
          label += ", ";
        }
        label += address.county;
      }

      if (this.isset(address.state)) {
        if (label != "") {
          label += ", ";
        }
        label += address.state;
      }

      if (this.isset(address.city)) {
        if (label != "") {
          label += ", ";
        }
        if (typeof address.city === "object") {
          label += address.city?.name || "";
        } else {
          label += address.city;
        }
      }

      if (this.isset(address.city)) {
        if (label != "") {
          label += ", ";
        }
        if (typeof address.city === "object") {
          label += address.city?.country_id || "";
        } else if (address.country_code) {
          label += address.country_code;
        }
      }

      return label.toUpperCase();
    },
    isset(payload) {
      return payload != undefined && payload != null && payload != "";
    },
    removeLettersFromWordsMixedWithNumber(sentence) {
      return sentence
        .split(" ")
        .map((word) => (/\d/.test(word) ? word.replace(/[^0-9]/g, "") : word))
        .join(" ");
    },
  },
};
</script>
<style>
.address_detail {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.primary--text .address_detail {
  width: 99%;
  position: absolute;
}
.primary--text input {
  width: 1% !important;
}
.location_name {
  font-size: 14px;
}
.location_address {
  font-size: 12px;
}
</style>
